import React from "react";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { Button, Stack, TextField, Typography,Autocomplete } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";

import { modelTypes } from './deviceList'

import { addNewDevice } from "src/redux/slices/adminDevices";
import useToggle from "src/hooks/useToggle";
import { ConfirmDialog } from "../devices/ConfirmDialog";

const NewDevice = () => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    toggle: openConfirm,
    onOpen: onOpenConfirm,
    onClose: onCloseConfirm,
  } = useToggle();

  const NewDeviceSchema = Yup.object().shape({
    serialNo: Yup.string().required("Serial No is required"),
    deviceModel: Yup.string().required("Device model is required"),
  });
  const initialValues = {
    serialNo: "",
    deviceModel: ""
  };

  

  
  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: NewDeviceSchema,
    onSubmit: async (values, { resetForm,setFieldValue }) => {
      console.table(values)
      try {
        onCloseConfirm();
        await addNewDevice(values.serialNo,values.deviceModel);
        resetForm();
        setFieldValue("deviceModel",  "")
        console.log(values.deviceModel)
        values.deviceModel = ""
        console.log(values.deviceModel)
        enqueueSnackbar("Device added successfully", { variant: "success" });
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      }
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps,setFieldValue, values } =
    formik;

  return (
    <>
      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Stack
            spacing={2}
            sx={{
              marginY: 4,
            }}
          > 
            <TextField
              fullWidth
              id="serialNo"
              label="Serial No"
              {...getFieldProps("serialNo")}
              disabled={isSubmitting}
              error={Boolean(touched.serialNo && errors.serialNo)}
              helperText={touched.serialNo && errors.serialNo}
            />

            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={modelTypes}
              disabled={isSubmitting}
              onChange={(e, value) => setFieldValue("deviceModel", value?.label || "")}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} error={Boolean(touched.deviceModel && errors.deviceModel)} helperText={touched.deviceModel && errors.deviceModel} name="deviceModel"  label="Model" />}
            />

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              Add New Device
            </LoadingButton>
          </Stack>
          <ConfirmDialog
      //   open={openConfirm}
      //   onClose={onCloseConfirm}
      //   title={
      //     <Typography gutterBottom>
      //       Are you sure you want to add this{" "}
      //       <strong>serial no : {values.serialNo}</strong> device?
      //     </Typography>
      //   }
      //   actions={
      //     <>
      //       <Button variant="outlined" color="inherit" onClick={onCloseConfirm}>
      //         Cancel
      //       </Button>
      //       <Button variant="contained" color="error" onClick={handleSubmit}>
      //         Add device
      //       </Button>
      //     </>
      //   }
      // />

            open={openConfirm}
            onClose={onCloseConfirm}
            title={
              <Typography gutterBottom>
                Are you sure you want to add this{" "}
                <strong>serial no : {values.serialNo}</strong> device?
              </Typography>
            }
            actions={
              <>
                <Button
                  variant="outlined"
                  color="inherit"
                  onClick={onCloseConfirm}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleSubmit}
                >
                  Add device
                </Button>
              </>
            }
          />
        </Form>
      </FormikProvider>
    </>
  );
};

export default NewDevice;
