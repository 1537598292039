import React from "react";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { Button, Stack, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";

import useToggle from "src/hooks/useToggle";
import { ConfirmDialog } from "../devices/ConfirmDialog";
import { doc, getDoc } from "firebase/firestore";
import { DB } from "src/contexts/FirebaseContext";
import { addNewActivationKey } from "src/redux/slices/adminDevices";

const NewActivationKeySchema = () => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    toggle: openConfirm,
    onOpen: onOpenConfirm,
    onClose: onCloseConfirm,
  } = useToggle();

  const NewActivationKeySchemaSchema = Yup.object().shape({
    activationKey: Yup.string()
      .required("Activation key is required")
      .min(5, "Minimum 5 numbers")
      .test(
        "Unique activation key",
        "This activation key has already been entered.",
        async (value) => {
          if (value?.length > 4) {
            const activationKeyRef = doc(DB, "activationKey", value);
            const docSnap = await getDoc(activationKeyRef);
            if (docSnap.exists()) {
              return false;
            }
            return true;
          } else return false;
        }
      ),
  });
  const initialValues = {
    activationKey: "",
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: NewActivationKeySchemaSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        onCloseConfirm();
        await addNewActivationKey(values.activationKey)
        resetForm();
        enqueueSnackbar("Device added successfully", { variant: "success" });
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      }
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, values } =
    formik;

  return (
    <>
      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Stack
            spacing={2}
            sx={{
              marginY: 4,
            }}
          >
            <TextField
              fullWidth
              id="activationKey"
              label="Activation Key"
              {...getFieldProps("activationKey")}
              disabled={isSubmitting}
              error={Boolean(touched.activationKey && errors.activationKey)}
              helperText={touched.activationKey && errors.activationKey}
            />

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              Add New Key
            </LoadingButton>
          </Stack>
          <ConfirmDialog
            open={openConfirm}
            onClose={onCloseConfirm}
            title={
              <Typography gutterBottom>
                Are you sure you want to add this{" "}
                <strong>Activation Key : {values.activationKey}</strong> device?
              </Typography>
            }
            actions={
              <>
                <Button
                  variant="outlined"
                  color="inherit"
                  onClick={onCloseConfirm}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleSubmit}
                >
                  Add device
                </Button>
              </>
            }
          />
        </Form>
      </FormikProvider>
    </>
  );
};

export default NewActivationKeySchema;
