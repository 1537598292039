import { Dialog, DialogActions, DialogTitle } from "@mui/material";
import React from "react";

export const ConfirmDialog = ({ title, subheader, actions, open, onClose }) => {
  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <DialogTitle>
        {title}
        {subheader}
      </DialogTitle>
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  );
};
