import { Box, Card, Stack, Typography, useTheme } from "@mui/material";
import merge from "lodash/merge";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { BaseOptionChart } from "../chart";

const MAX_LIMIT = 9000;
const COLORS = ["primary", "warning", "error"];

const DeviceDetailsCard = (props) => {
  const { device, userDetails } = props;
  const theme = useTheme();
  const [color, setColor] = useState(COLORS[0]);

    const getColor = (value) => {
      if (value < 8000) {
        return COLORS[0];
      } else if (value < 8500) {
        return COLORS[1];
      } else {
        return COLORS[2];
      }
  };
  
  useEffect(() => {
    setColor(getColor(device?.runningHrs));
  }, [device?.runningHrs]);

  const convertToPercentage = (value) => {
    const percentage = (value * 100) / MAX_LIMIT;
    return percentage.toFixed(2);
  };

  const chartOptions = merge(BaseOptionChart(), {
    colors: [theme.palette[color].main],
    chart: { sparkline: { enabled: true } },
    legend: { show: false },
    labels: ["Percent"],
    plotOptions: {
      radialBar: {
        hollow: { size: "78%" },
        track: { margin: 0 },
        dataLabels: {
          name: { show: false },
          value: {
            formatter: function (val) {
              return val;
            },
            offsetY: 6,
            color: theme.palette[color].main,
            fontSize: theme.typography.subtitle2.fontSize,
            show: true,
          },
        },
      },
    },
  });

  return (
    <Card>
      <Stack
        direction="row"
        sx={{
          width: "100%",
          marginBottom: 4,
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <Box
          sx={{
            width: "fit-content",
          }}
        >
          <ReactApexChart
            type="radialBar"
            series={[convertToPercentage(device?.runningHrs)]}
            options={chartOptions}
            width={200}
            height={200}
          />

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography variant="body2">
              Running Hrs :{" "}
              <b
                style={{
                  color: theme.palette[color].main,
                  fontSize: "1.2em",
                }}
              >
                {device?.runningHrs}
              </b>{" "}
              hrs
            </Typography>
          </Box>
        </Box>

        <Box sx={{ paddingY: 4 }}>
          <Typography variant="h5">
            Display name : {userDetails?.displayName}
          </Typography>
          <Typography variant="h5">Email : {userDetails?.email}</Typography>
          <Typography variant="h5">
            Phone number : {userDetails?.phoneNumber}
          </Typography>
        </Box>
      </Stack>
    </Card>
  );
};

export default DeviceDetailsCard;
