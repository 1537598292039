import {
  Card,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import useTable from "src/hooks/useTable";
import Scrollbar from "../Scrollbar";
import Iconify from "../../components/Iconify";
import { TableHeadCustom, TableMoreMenu, TableSkeleton } from "../table";
import {
  removeActivationKey,
} from "src/redux/slices/adminDevices";
import TableToolbar from "./TableToolbar";
import { getActivationKeys } from "src/redux/slices/adminDevices";

const TABLE_HEAD = [
  { id: "activationKey", label: "Activation key", align: "left" },
  { id: "email", label: "Email", align: "left" },
  { id: "" },
];

const ActivationKeysTable = () => {
  const isLoading = useSelector((state) => state.adminDevices.isLoading);
  const allActivationKeys = useSelector(
    (state) => state.adminDevices.allActivationKeys
  );

  const { page, setPage, rowsPerPage, onChangePage, onChangeRowsPerPage } =
    useTable();
  const [openMenu, setOpenMenuActions] = useState(null);
  const [searchingActivationKey, setSearchingActivationKey] = useState("");
  const [filteredActivationKeys, setFilteredActivationKeys] =
    useState(allActivationKeys);

  useEffect(() => {
    fetchActivationKeys();
  }, []);

  const fetchActivationKeys = async () => {
    getActivationKeys();
  };

  useEffect(() => {
    if (searchingActivationKey === "") {
      setFilteredActivationKeys(allActivationKeys);
      return;
    }

    const tempFilteredActivationKeys = allActivationKeys.filter((item) =>
      item.key.toLowerCase().includes(searchingActivationKey?.toLowerCase())
    );
    setFilteredActivationKeys(tempFilteredActivationKeys);
  }, [searchingActivationKey, allActivationKeys]);

  const handleOnSearchingActivationKey = (serialNo) => {
    setSearchingActivationKey(serialNo);
    setPage(0);
  };

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  return (
    <Card>
      <TableToolbar
        searchingValue={searchingActivationKey}
        handleOnSearch={handleOnSearchingActivationKey}
        placeholder="Search by activation key"
      />
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800, position: "relative" }}>
          <Table size="medium">
            <TableHeadCustom headLabel={TABLE_HEAD} />
            <TableBody>
              {filteredActivationKeys
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return !isLoading ? (
                    <TableRow hover key={index}>
                      <TableCell align="left">{row?.key}</TableCell>
                      <TableCell align="left">{row?.email}</TableCell>
                      <TableCell align="right">
                        <TableMoreMenu
                          key={`${index}-actions`}
                          open={openMenu}
                          onOpen={handleOpenMenu}
                          onClose={handleCloseMenu}
                          actions={
                            <>
                              <MenuItem
                                onClick={() => {
                                  handleCloseMenu();
                                  console.log(row?.key);
                                  removeActivationKey(row?.key);
                                }}
                                sx={{ color: "error.main" }}
                                key={`${index}-delete`}
                              >
                                <Iconify icon={"eva:trash-2-outline"} />
                                Delete
                              </MenuItem>
                            </>
                          }
                        />
                      </TableCell>
                    </TableRow>
                  ) : (
                    <TableSkeleton key={index} />
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      <Box sx={{ position: "relative" }}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={allActivationKeys.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
      </Box>
    </Card>
  );
};

export default ActivationKeysTable;
