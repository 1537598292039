import { Container } from "@mui/material";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import UsersTable from "src/components/adminDeviceManagement/UsersTable";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import Page from "src/components/Page";
import useSettings from "src/hooks/useSettings";
import { getAllDevice, getAllUsers } from "src/redux/slices/adminDevices";
import { PATH_DASHBOARD } from "src/routes/paths";

const Users = () => {
  const { themeStretch } = useSettings();
  const allUsers = useSelector((state) => state.adminDevices.allUsers);
  const isLoading = useSelector((state) => state.adminDevices.isLoading);

  useEffect(() => {
    getAllUsers();
    getAllDevice();
  }, []);

  return (
    <Page title="Users">
      <Container maxWidth={themeStretch ? false : "lg"}>
        <HeaderBreadcrumbs
          heading="Users"
          links={[
            { name: "Dashboard", href: PATH_DASHBOARD.root },
            {
              name: "Users",
              href: PATH_DASHBOARD.adminDeviceManagement.users,
            },
          ]}
        />
        <UsersTable allUsers={allUsers} isLoading={isLoading} />
      </Container>
    </Page>
  );
};

export default Users;
