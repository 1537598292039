import { Container } from "@mui/material";
import React from "react";
import { useLocation, useParams } from "react-router";
import { useSelector } from "react-redux";

// hooks
import useSettings from "src/hooks/useSettings";

// routes
import { PATH_DASHBOARD } from "src/routes/paths";

// components
import DeviceNewForm from "src/components/devices/DeviceNewForm";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import Page from "src/components/Page";

const NewDevice = () => {
  const { themeStretch } = useSettings();
  const { pathname } = useLocation();
  const { serialNo } = useParams();
  const isEdit = pathname.includes("edit");
  const devices = useSelector((state) => state.device.devices);
  const currentDevice = devices.find((device) => device.serialNo === serialNo);

  return (
    <Page title="Device: Create a new device">
      <Container maxWidth={themeStretch ? false : "lg"}>
        <HeaderBreadcrumbs
          heading={!isEdit ? "Create a new product" : "Edit product"}
          links={[
            { name: "Dashboard", href: PATH_DASHBOARD.root },
            {
              name: "Device",
              href: PATH_DASHBOARD.devices.root,
            },
            { name: !isEdit ? "Add new device" : currentDevice.name },
          ]}
        />
        <DeviceNewForm isEdit={isEdit} currentDevice={currentDevice} />
      </Container>
    </Page>
  );
};

export default NewDevice;
