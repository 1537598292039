import { useTheme } from "@emotion/react";
import {
  Box,
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import merge from "lodash/merge";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";
import useTable from "src/hooks/useTable";
import { BaseOptionChart } from "../chart";
import Scrollbar from "../Scrollbar";
import { TableHeadCustom, TableSkeleton } from "../table";
import TableToolbar from "./TableToolbar";
import UserDeviceTableToolbar from "./UserDeviceTableToolbar";

const MAX_LIMIT = 9000;
const COLORS = ["primary", "warning", "error"];
const TABLE_HEAD = [
  { id: "serialNumber", label: "Serial Number", align: "left" },
  { id: "runningHrs", label: "Running Hrs", align: "left" },
  { id: "burnedPercentage", label: "Burned Percentage", align: "left" },
];

const UserDevicesTable = (props) => {
  const { devices } = props;
  const theme = useTheme();
  const [filteredDevices, setFilteredDevices] = useState(devices);
  const isLoading = useSelector((state) => state.adminDevices.isLoading);
  const [filterSerialNo, setFilterSerialNo] = useState("");

  const { page, setPage, rowsPerPage, onChangePage, onChangeRowsPerPage } =
    useTable();

  const chartOptions = (color) =>
    merge(BaseOptionChart(), {
      colors: [theme.palette[color].main],
      chart: { sparkline: { enabled: true } },
      legend: { show: false },
      labels: ["Percent"],
      plotOptions: {
        radialBar: {
          hollow: { size: "78%" },
          track: { margin: 0 },
          dataLabels: {
            name: { show: false },
            value: {
              formatter: function (val) {
                return val;
              },
              offsetY: 6,
              color: theme.palette[color].main,
              fontSize: theme.typography.body2.fontSize,
              show: true,
            },
          },
        },
      },
    });

  const getColor = (value) => {
    if (value < 8000) {
      return COLORS[0];
    } else if (value < 8500) {
      return COLORS[1];
    } else {
      return COLORS[2];
    }
  };

  const convertToPercentage = (value) => {
    const percentage = (value * 100) / MAX_LIMIT;
    return percentage.toFixed(2);
  };

  const handleOnFilterSerialNo = (serialNo) => {
    setFilterSerialNo(serialNo);
    setPage(0);
  };

  useEffect(() => {
    if (filterSerialNo === "") {
      setFilteredDevices(devices);
      return;
    }

    const tempFilteredDevices = devices.filter((device) =>
      device.serialNo.toLowerCase().includes(filterSerialNo.toLowerCase())
    );
    setFilteredDevices(tempFilteredDevices);
  }, [filterSerialNo, devices]);

  // const renderDevice = (device) => {
  //   const percentage = convertToPercentage(device?.runningHrs);
  //   const color = getColor(device?.runningHrs);

  //   return (
  //     <Grid item xs={12} sm={6} md={4} lg={3} key={device.id}>
  //       <Box
  //         sx={{
  //           width: "fit-content",
  //         }}
  //       >
  //         <ReactApexChart
  //           type="radialBar"
  //           series={[percentage]}
  //           options={chartOptions(color)}
  //           width={200}
  //           height={200}
  //         />

  //         <Box
  //           sx={{
  //             display: "flex",
  //             flexDirection: "column",
  //             alignItems: "center",
  //           }}
  //         >
  //           <Typography variant="body2">
  //             Running Hrs :{" "}
  //             <b
  //               style={{
  //                 color: theme.palette[color].main,
  //                 fontSize: "1.2em",
  //               }}
  //             >
  //               {device?.runningHrs}
  //             </b>{" "}
  //             hrs
  //           </Typography>
  //           <Typography variant="body2">
  //               Serial No : {device?.serialNo}
  //           </Typography>
  //         </Box>
  //       </Box>
  //     </Grid>
  //   );
  // };

  return (
    <Card>
      <TableToolbar
        searchingValue={filterSerialNo}
        handleOnSearch={handleOnFilterSerialNo}
        placeholder={"Search by serial no"}
      />
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800, position: "relative" }}>
          <Table size="medium">
            <TableHeadCustom headLabel={TABLE_HEAD} />
            <TableBody>
              {filteredDevices
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) =>
                  !isLoading ? (
                    <TableRow hover key={row?.serialNo}>
                      <TableCell align="left">{row?.serialNo}</TableCell>
                      <TableCell align="left">{row?.runningHrs}</TableCell>
                      <TableCell align="center">
                        <ReactApexChart
                          type="radialBar"
                          series={[convertToPercentage(row?.runningHrs)]}
                          options={chartOptions(getColor(row?.runningHrs))}
                          width={75}
                          height={75}
                        />
                      </TableCell>
                    </TableRow>
                  ) : (
                    <TableSkeleton key={index} />
                  )
                )}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
      <Box sx={{ position: "relative" }}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={devices.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
      </Box>
    </Card>
  );
};

export default UserDevicesTable;
