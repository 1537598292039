import {
  Card,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import useTable from "src/hooks/useTable";
import Scrollbar from "../Scrollbar";
import Iconify from "../../components/Iconify";
import { TableHeadCustom, TableMoreMenu, TableSkeleton } from "../table";
import { removeDevice } from "src/redux/slices/adminDevices";
import TableToolbar from "./TableToolbar";
import { PATH_DASHBOARD } from "src/routes/paths";
import { paramCase } from "change-case";

const TABLE_HEAD = [
  { id: "serialNumber", label: "Serial Number", align: "left" },
  { id: "deviceModel", label: "Model", align: "left" },
  { id: "isUserAssigned", label: "User", align: "left" },
  { id: "" },
];

const DeviceListTable = () => {
  const allDevices = useSelector((state) => state.adminDevices.allDevices);
  const allUsers = useSelector((state) => state.adminDevices.allUsers);
  const isLoading = useSelector((state) => state.adminDevices.isLoading);

  const { page, setPage, rowsPerPage, onChangePage, onChangeRowsPerPage } =
    useTable();
  const [openMenu, setOpenMenuActions] = useState(null);
  const [filterSerialNo, setFilterSerialNo] = useState("");
  const [filteredDevices, setFilteredDevices] = useState(allDevices);

  const navigate = useNavigate();

  useEffect(() => {
    if (filterSerialNo === "") {
      setFilteredDevices(allDevices);
      return;
    }

    const tempFilteredDevices = allDevices.filter((device) =>
      device.serialNo.toLowerCase().includes(filterSerialNo.toLowerCase())
    );
    setFilteredDevices(tempFilteredDevices);
  }, [filterSerialNo, allDevices]);

  const handleOnFilterSerialNo = (serialNo) => {
    setFilterSerialNo(serialNo);
    setPage(0);
  };

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const getUserEmailsByUserId = (userId) => {
    if (!userId || allUsers.length === 0) return;

    const userDetails = allUsers.find((user) => user.uid === userId);
    return userDetails?.email;
  };

  return (
    <Card>
      <TableToolbar
        searchingValue={filterSerialNo}
        handleOnSearch={handleOnFilterSerialNo}
        placeholder={"Search by serial no"}
      />
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800, position: "relative" }}>
          <Table size="medium">
            <TableHeadCustom headLabel={TABLE_HEAD} />
            <TableBody>
              {filteredDevices
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) =>
                  !isLoading ? (
                    <TableRow hover key={index}>
                      <TableCell align="left">{row?.serialNo}</TableCell>
                      <TableCell align="left">
                        {row.deviceModel}
                      </TableCell>
                      <TableCell align="left">
                        {getUserEmailsByUserId(row?.userId) ?  getUserEmailsByUserId(row?.userId) : "-" }
                      </TableCell>
                      <TableCell align="right">
                        <TableMoreMenu
                          key={`${index}-actions`}
                          open={openMenu}
                          onOpen={handleOpenMenu}
                          onClose={handleCloseMenu}
                          actions={
                            <>
                              { (
                                <MenuItem
                                  onClick={() => {
                                    handleCloseMenu();
                                    console.log(row?.serialNo)
                                    removeDevice(row?.serialNo);
                                  }}
                                  sx={{ color: "error.main" }}
                                  key={`${index}-delete`}
                                >
                                  <Iconify icon={"eva:trash-2-outline"} />
                                  Delete
                                </MenuItem>
                              )}
                              <MenuItem
                                key={`${index}-view`}
                                onClick={() => {
                                  handleCloseMenu();
                                  console.log(index);
                                  navigate(
                                    PATH_DASHBOARD.adminDeviceManagement.details(
                                      paramCase(row?.serialNo)
                                    )
                                  );
                                }}
                              >
                                <Iconify icon={"material-symbols:read-more"} />
                                View
                              </MenuItem>
                            </>
                          }
                        />
                      </TableCell>
                    </TableRow>
                  ) : (
                    <TableSkeleton key={row.serialNo} />
                  )
                )}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      <Box sx={{ position: "relative" }}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={allDevices.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
      </Box>
    </Card>
  );
};

export default DeviceListTable;
