import React, { useEffect, useState } from "react";
import merge from "lodash/merge";

// @mui
import { useTheme, styled } from "@mui/material/styles";
import { Card, Typography, Box, Stack, Button } from "@mui/material";

import { Link as RouterLink } from "react-router-dom";

// components
import Iconify from "../Iconify";
import { BaseOptionChart } from "../chart";
import ReactApexChart from "react-apexcharts";
import { PATH_DASHBOARD } from "src/routes/paths";
import { useSnackbar } from "notistack";
import { removeDevice } from "src/redux/slices/userDevices";
import useAuth from "src/hooks/useAuth";
import useToggle from "src/hooks/useToggle";
import { ConfirmDialog } from "./ConfirmDialog";

const RootStyle = styled(Card)(({ theme }) => ({
  direction: "row",
  alignItems: "center",
  padding: theme.spacing(3),
  backgroundColor: theme.palette.primary.main,
}));

const IconStyle = styled(Iconify)(({ theme }) => ({
  width: 120,
  height: 120,
  opacity: 0.12,
  position: "absolute",
  right: theme.spacing(-3),
  color: theme.palette.common.white,
}));

const MAX_LIMIT = 9000;
const COLORS = ["secondary", "warning", "error"];

const DeviceCard = (props) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();
  const { device } = props;
  const {
    name,
    status,
    runningHrs,
    serialNo,
    runningHrsTwo,
    lastUpdatedTime,
    deviceModel,
    deviceTwoStatus,
    deviceStatus,
  } = device;
  const {
    toggle: openConfirm,
    onOpen: onOpenConfirm,
    onClose: onCloseConfirm,
  } = useToggle();

  const [color, setColor] = useState(COLORS[0]);
  const [colorTwo, setColorTwo] = useState(COLORS[0]);

  const convertToPercentage = (value) => {
    const percentage = (value * 100) / MAX_LIMIT;
    return percentage.toFixed(2);
  };

  const getColor = (value, status) => {
    if (status === "BURNT") {
      return COLORS[2];
    }
    if (value < 8000) {
      return COLORS[0];
    } else if (value < 9000) {
      return COLORS[1];
    } else {
      return COLORS[2];
    }
  };

  useEffect(() => {
    setColor(getColor(runningHrs, deviceStatus));
    setColorTwo(getColor(runningHrsTwo, deviceTwoStatus));
  }, [runningHrs]);

  const chartOptions = merge(BaseOptionChart(), {
    colors: [theme.palette[color].main],
    chart: { sparkline: { enabled: true } },
    legend: { show: false },
    labels: ["Percent"],
    plotOptions: {
      radialBar: {
        hollow: { size: "78%" },
        track: { margin: 0 },
        dataLabels: {
          name: { show: false },
          value: {
            formatter: function (val) {
              return val;
            },
            offsetY: 6,
            color: theme.palette[color].main,
            fontSize: theme.typography.subtitle2.fontSize,
            show: true,
          },
        },
      },
    },
  });

  const chartOptionsTwo = merge(BaseOptionChart(), {
    colors: [theme.palette[colorTwo].main],
    chart: { sparkline: { enabled: true } },
    legend: { show: false },
    labels: ["Percent"],
    plotOptions: {
      radialBar: {
        hollow: { size: "78%" },
        track: { margin: 0 },
        dataLabels: {
          name: { show: false },
          value: {
            formatter: function (val) {
              return val;
            },
            offsetY: 6,
            color: theme.palette[colorTwo].main,
            fontSize: theme.typography.subtitle2.fontSize,
            show: true,
          },
        },
      },
    },
  });
  const date = new Date(lastUpdatedTime);
  console.log(lastUpdatedTime);
  console.log(date);

  const deviceStatusText = (mode) => {
    if (mode === "TWO") {
      if (deviceTwoStatus === "BURNT") {
        return "BURNT";
      } else if (runningHrsTwo >= 0 && runningHrsTwo < 8000) {
        return "GOOD";
      } else if (runningHrsTwo >= 8000 && runningHrsTwo < 9000) {
        return "ORDER REPLACEMENT";
      } else {
        return "REPLACE REQUIRED";
      }
    } else {
      if (deviceStatus === "BURNT") {
        return "BURNT";
      } else if (runningHrs >= 0 && runningHrs < 8000) {
        return "GOOD";
      } else if (runningHrs >= 8000 && runningHrs < 9000) {
        return "ORDER REPLACEMENT";
      } else {
        return "REPLACE REQUIRED";
      }
    }
  };

  const handleOnRemoveDevice = async () => {
    try {
      onCloseConfirm();
      await removeDevice(serialNo, user.id);
      enqueueSnackbar(`Device with ${serialNo} removed`, { variant: "error" });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  return (
    <>
      <ConfirmDialog
        open={openConfirm}
        onClose={onCloseConfirm}
        title={
          <Typography gutterBottom>
            Are you sure you want to add this device?
          </Typography>
        }
        actions={
          <>
            <Button variant="outlined" color="inherit" onClick={onCloseConfirm}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={handleOnRemoveDevice}
            >
              Remove device
            </Button>
          </>
        }
      />

      <RootStyle
        sx={{
          background: theme.palette.common.white,
        }}
      >
        <Stack direction="row" sx={{ width: "100%", marginBottom: 4 }}>
          <ReactApexChart
            type="radialBar"
            series={[convertToPercentage(runningHrs)]}
            options={chartOptions}
            width={86}
            height={86}
          />
          {runningHrsTwo !== "" ? (
            <ReactApexChart
              type="radialBar"
              series={[convertToPercentage(runningHrsTwo)]}
              options={chartOptionsTwo}
              width={86}
              height={86}
            />
          ) : null}

          <Box sx={{ ml: 3, color: "black" }}>
            <Typography variant="h4"> {name}</Typography>
            <Typography variant="body2">
              Running Hours Bulb One:{" "}
              <b
                style={{
                  color: theme.palette[color].main,
                  fontSize: "1.2em",
                }}
              >
                {runningHrs}
              </b>{" "}
            </Typography>
            {runningHrsTwo !== "" ? (
              <Typography variant="body2">
                Running Hours Bulb Two:{" "}
                <b
                  style={{
                    color: theme.palette[colorTwo].main,
                    fontSize: "1.2em",
                  }}
                >
                  {runningHrsTwo}
                </b>{" "}
              </Typography>
            ) : (
              <Typography style={{ opacity: "0" }}>Running Hrs (B2)</Typography>
            )}

            <Typography variant="body2">
              Bulb One Status:{" "}
              <b
                style={{
                  color: theme.palette[color].main,
                  fontSize: "1.2em",
                }}
              >
                {deviceStatusText("ONE")}
              </b>{" "}
            </Typography>

            {runningHrsTwo !== "" ? (
              <Typography variant="body2">
                Bulb Two Status:{" "}
                <b
                  style={{
                    color: theme.palette[colorTwo].main,
                    fontSize: "1.2em",
                  }}
                >
                  {deviceStatusText("TWO")}
                  {/* {deviceTwoStatus} */}
                </b>{" "}
              </Typography>
            ) : (
              <Typography style={{ opacity: "0" }}>Running Hrs (B2)</Typography>
            )}

            <Typography variant="body2">Serial No : {serialNo}</Typography>
            <Typography variant="body2">
              Device Model : {deviceModel}
            </Typography>
            <Typography variant="body2">
              Last Updated Time : {date.toLocaleDateString("default")} -{" "}
              {date.toLocaleTimeString("default")}
            </Typography>
          </Box>
          {/* //TODO back lamp */}
          {/* <IconStyle
            icon={"bi:lamp"}
            sx={{
              color: theme.palette[color].main,
            }}
          /> */}
        </Stack>
        <Stack direction="row" spacing={3}>
          <Button
            variant="contained"
            component={RouterLink}
            to={PATH_DASHBOARD.devices.edit(serialNo)}
            startIcon={<Iconify icon={"material-symbols:edit-square"} />}
          >
            Modify
          </Button>

          <Button
            variant="contained"
            startIcon={<Iconify icon={"material-symbols:delete-outline"} />}
            onClick={onOpenConfirm}
          >
            Remove
          </Button>
        </Stack>
      </RootStyle>
    </>
  );
};

export default DeviceCard;
