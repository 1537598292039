import {
  Box,
  Card,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from "@mui/material";
import { paramCase } from "change-case";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import useTable from "src/hooks/useTable";
import { selectUserOnUserTable } from "src/redux/slices/adminDevices";
import { PATH_DASHBOARD } from "src/routes/paths";
import Iconify from "../Iconify";
import Scrollbar from "../Scrollbar";
import { TableHeadCustom, TableMoreMenu, TableSkeleton } from "../table";
import TableToolbar from "./TableToolbar";
import UserTableToolbar from "./UserTableToolbar";

const TABLE_HEAD = [
  { id: "email", label: "Email", align: "left" },
  { id: "displayName", label: "Display name", align: "left" },
  { id: "phoneNumber", label: "Phone number", align: "left" },
  { id: "" },
];

const UsersTable = (props) => {
  const { allUsers, isLoading } = props;
  const navigate = useNavigate();
  const { page, setPage, rowsPerPage, onChangePage, onChangeRowsPerPage } =
    useTable();
  const [filteredUsers, setFilteredUsers] = useState(allUsers);
  const [openMenu, setOpenMenuActions] = useState(null);
  const [filterUserEmail, setFilterUserEmail] = useState("");

  const handleOnFilterUserEmail = (serialNo) => {
    setFilterUserEmail(serialNo);
    setPage(0);
  };

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  useEffect(() => {
    if (filterUserEmail === "") {
      setFilteredUsers(allUsers);
      return;
    }

    const tempFilteredUsers = allUsers.filter((user) =>
      user.email.toLowerCase().includes(filterUserEmail.toLowerCase())
    );
    setFilteredUsers(tempFilteredUsers);
  }, [filterUserEmail, allUsers]);

  return (
    <Card>
      <TableToolbar
        searchingValue={filterUserEmail}
        handleOnSearch={handleOnFilterUserEmail}
        placeholder={"Search by user email"}
      />
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800, position: "relative" }}>
          <Table size="medium">
            <TableHeadCustom headLabel={TABLE_HEAD} />
            <TableBody>
              {filteredUsers
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) =>
                  !isLoading ? (
                    <TableRow hover key={index}>
                      <TableCell align="left">{row?.email}</TableCell>
                      <TableCell align="left">{row?.displayName}</TableCell>
                      <TableCell align="left">{row?.phoneNumber}</TableCell>
                      <TableCell align="right">
                        <TableMoreMenu
                          open={openMenu}
                          onOpen={handleOpenMenu}
                          onClose={handleCloseMenu}
                          actions={
                            <>
                              {/* <MenuItem
                                onClick={() => {
                                  handleCloseMenu();
                                }}
                                sx={{ color: "error.main" }}
                              >
                                <Iconify icon={"eva:trash-2-outline"} />
                                Delete
                              </MenuItem> */}
                              <MenuItem
                                onClick={() => {
                                  selectUserOnUserTable(row?.uid);
                                  handleCloseMenu();
                                  navigate(
                                    PATH_DASHBOARD.adminDeviceManagement.userDevices(
                                      paramCase(row?.uid)
                                    )
                                  );
                                }}
                              >
                                <Iconify icon={"material-symbols:read-more"} />
                                View
                              </MenuItem>
                            </>
                          }
                        />
                      </TableCell>
                    </TableRow>
                  ) : (
                    <TableSkeleton key={index} />
                  )
                )}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
      <Box sx={{ position: "relative" }}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={allUsers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
      </Box>
    </Card>
  );
};

export default UsersTable;
