import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  // OR\

  const logo = (
    <Box sx={{ width: 100, height: 100, ...sx }}>
      {/* <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="100%" viewBox="0 0 834 488" enable-background="new 0 0 834 488" xmlSpace="preserve">
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M595.000000,489.000000 
	C396.666687,489.000000 198.833405,489.000000 1.000080,489.000000 
	C1.000053,326.333405 1.000053,163.666794 1.000027,1.000145 
	C278.999878,1.000097 556.999756,1.000097 834.999756,1.000048 
	C834.999817,163.666519 834.999817,326.333038 834.999878,488.999786 
	C755.166687,489.000000 675.333313,489.000000 595.000000,489.000000 
M530.091187,51.131001 
	C530.091187,51.131001 530.208313,51.104351 530.735535,50.691681 
	C538.801453,37.312893 546.867371,23.934101 555.265015,10.005188 
	C542.265808,10.005188 530.172546,9.990561 518.079590,10.042015 
	C517.364197,10.045058 516.651001,10.579448 515.393677,11.285173 
	C507.549774,24.370941 499.705872,37.456707 491.283264,50.955956 
	C483.189789,64.205009 475.096313,77.454071 466.678558,91.233994 
	C479.881256,91.233994 492.413605,91.233994 505.547455,91.409348 
	C513.712219,77.946754 521.877014,64.484154 530.091187,51.131001 
M149.921463,444.053314 
	C149.709290,443.873688 149.497101,443.694061 149.203781,442.651550 
	C149.203781,436.701080 149.203781,430.750641 149.203781,424.852386 
	C146.725708,424.852386 144.689804,424.852386 142.565613,424.852386 
	C142.565613,442.363983 142.565613,459.452759 142.565613,477.940216 
	C149.605194,478.373047 156.639633,479.378998 163.617569,479.077606 
	C170.984131,478.759369 175.176880,474.443115 175.952240,467.145660 
	C176.491272,462.072449 176.546158,456.839417 175.956894,451.777954 
	C174.841812,442.199829 167.588425,437.656799 158.365631,440.537933 
	C155.588745,441.405426 153.117340,443.250763 149.921463,444.053314 
M569.115784,323.589691 
	C532.456848,367.321014 472.422363,370.050781 432.942657,348.058289 
	C387.633209,322.818390 365.492523,269.703491 379.773315,220.917953 
	C391.096924,182.234634 436.503540,135.938400 492.931000,140.180954 
	C533.880920,143.259842 582.949036,178.004135 587.048828,207.299561 
	C530.072632,207.299561 473.160248,207.299561 416.073944,207.299561 
	C416.073944,218.019852 416.073944,228.270737 416.073944,238.656647 
	C486.827454,238.656647 557.343567,238.656647 627.889832,238.656647 
	C621.465210,166.436295 563.007263,115.080650 500.904053,109.209389 
	C417.642487,101.337799 364.376495,159.066162 349.656403,209.545273 
	C330.275360,276.007965 360.850800,347.472931 422.923492,378.386353 
	C480.866547,407.243164 550.053528,393.739532 597.085632,339.839264 
	C589.432007,332.781891 581.770569,325.717377 573.864929,318.427643 
	C572.255005,320.172882 570.922668,321.617157 569.115784,323.589691 
M100.799347,350.806580 
	C53.583710,325.006653 30.314972,273.981812 42.572662,223.526611 
	C59.863102,152.355591 150.962769,109.857346 219.363312,163.839798 
	C247.958618,186.407547 262.551392,215.636703 262.116943,252.522903 
	C261.606750,295.838379 261.997406,339.164490 261.997437,382.485992 
	C261.997437,384.262146 261.997467,386.038300 261.997467,387.848816 
	C272.650909,387.848816 282.717468,387.848816 293.162292,387.848816 
	C293.162292,386.071106 293.162292,384.603760 293.162292,383.136444 
	C293.162292,337.315613 293.210663,291.494720 293.135956,245.674026 
	C293.109924,229.711044 290.309784,214.148682 284.339661,199.358322 
	C243.662964,98.586121 114.603302,77.153252 45.654472,154.711060 
	C5.133765,200.291199 -3.111902,252.719650 20.032249,308.511536 
	C42.748512,363.272003 106.364342,405.269562 178.736526,390.541901 
	C190.467697,388.154633 201.760620,383.613861 213.646454,379.948120 
	C208.984634,369.327240 204.930634,360.091156 200.670624,350.385712 
	C167.508575,365.004486 134.505768,366.557770 100.799347,350.806580 
M755.508789,159.963287 
	C776.636536,144.162018 800.701721,138.156113 826.756592,139.186462 
	C826.756592,128.413101 826.756592,118.217789 826.756592,107.259232 
	C816.688965,107.958847 806.997437,108.048729 797.482300,109.390022 
	C743.266418,117.032471 676.593811,166.779739 677.356140,250.738739 
	C677.755493,294.721222 677.423645,338.710297 677.437317,382.696411 
	C677.437805,384.436707 677.596252,386.176941 677.680420,387.903534 
	C688.379700,387.903534 698.472961,387.903534 709.004089,387.903534 
	C709.004089,385.866211 709.004028,384.067688 709.004028,382.269135 
	C709.004028,338.116425 708.944458,293.963531 709.072815,249.811188 
	C709.090637,243.682327 709.638123,237.491714 710.618835,231.439636 
	C715.453247,201.606354 731.472229,178.653793 755.508789,159.963287 
M474.555054,440.362091 
	C473.699097,428.288727 471.032410,425.163116 459.990540,424.535004 
	C453.065033,424.141083 446.098938,424.461639 438.986328,424.461639 
	C438.986328,442.937012 438.986328,460.505127 438.986328,478.121399 
	C441.379730,478.121399 443.552765,478.121399 446.084473,478.121399 
	C446.084473,470.591980 446.084473,463.494995 446.084473,456.559296 
	C449.929932,456.559296 453.408997,456.616974 456.885315,456.544037 
	C460.805084,456.461853 463.091705,458.392456 464.110626,462.061737 
	C465.130707,465.735229 466.175354,469.401886 467.204041,473.072998 
	C468.937225,479.258148 468.935516,479.258636 476.367126,477.898865 
	C472.803802,469.891113 473.382965,460.351837 465.751556,453.723145 
	C472.793610,452.154510 474.104370,446.968628 474.555054,440.362091 
M99.195267,476.630737 
	C99.196243,470.305573 99.749596,463.917175 99.035263,457.673859 
	C98.384346,451.984741 101.323395,449.539093 105.528732,447.380829 
	C112.992683,443.550201 117.017838,445.856720 117.113411,454.191010 
	C117.204491,462.132324 117.133041,470.075500 117.133041,478.144775 
	C119.635498,478.144775 121.669540,478.144775 123.864319,478.144775 
	C123.864319,468.423523 123.958420,459.105316 123.832153,449.790100 
	C123.743813,443.273041 120.247055,439.423645 113.897972,440.172150 
	C108.706978,440.784149 103.717834,443.108398 98.340485,444.765594 
	C93.502632,437.794373 88.032768,437.817383 74.231560,445.673553 
	C73.920982,444.120667 73.610413,442.567780 73.307564,441.053558 
	C71.360191,441.053558 69.765007,441.053558 68.184677,441.053558 
	C68.184677,453.566711 68.184677,465.826965 68.184677,478.216431 
	C70.452400,478.216431 72.508827,478.216431 74.865189,478.216431 
	C74.865189,470.419189 74.854790,462.952393 74.868851,455.485657 
	C74.878204,450.519318 80.298523,446.009644 86.391205,445.868286 
	C91.254250,445.755463 92.741486,447.769958 92.789597,454.825500 
	C92.842728,462.617126 92.801727,470.409363 92.801727,478.996521 
	C95.302216,478.392639 97.231621,477.926697 99.195267,476.630737 
M539.454285,464.036957 
	C539.599121,465.023773 539.741516,466.010925 539.889099,466.997314 
	C541.482910,477.647095 548.734314,482.103760 558.920471,478.476257 
	C561.680969,477.493195 564.198853,475.828674 567.115234,474.329346 
	C567.714966,477.642090 569.051697,479.993805 572.976929,477.948212 
	C572.976929,460.248932 572.976929,442.514191 572.976929,424.879669 
	C570.701355,424.879669 568.663574,424.879669 566.438293,424.879669 
	C566.438293,428.136230 566.438293,430.953094 566.438293,433.769958 
	C566.438293,436.562469 566.438293,439.354950 566.438293,442.419312 
	C563.915100,441.693573 561.598938,440.883728 559.219116,440.367737 
	C548.905945,438.131744 541.805298,441.679565 540.202209,450.267365 
	C539.419678,454.459015 539.655823,458.840851 539.454285,464.036957 
M668.974915,451.499695 
	C668.974915,460.427185 668.974915,469.354706 668.974915,478.178101 
	C671.485107,478.178101 673.375549,478.178101 675.253845,478.178101 
	C675.253845,465.570404 675.253845,453.342926 675.253845,440.715271 
	C667.051453,440.715271 659.143311,440.715271 651.426270,440.715271 
	C648.171631,428.704712 655.536255,427.961151 664.080505,428.984711 
	C663.972961,426.737946 663.903381,425.285461 663.769104,422.481018 
	C658.299194,423.282562 652.644653,422.926239 648.167908,425.051300 
	C641.820129,428.064545 644.791443,435.402008 643.634277,440.736633 
	C641.384521,440.953033 639.443481,441.139740 637.346680,441.341431 
	C637.346680,443.059540 637.346680,444.489868 637.346680,445.984650 
	C639.643982,446.161499 641.733032,446.322327 644.119263,446.506042 
	C644.119263,457.269562 644.119263,467.708282 644.119263,478.197998 
	C646.512573,478.197998 648.569885,478.197998 650.966064,478.197998 
	C650.966064,467.388336 650.966064,456.932159 650.966064,446.368774 
	C657.093079,446.368774 662.875732,446.368774 668.974915,446.368774 
	C668.974915,447.852814 668.974915,449.170685 668.974915,451.499695 
M43.953888,447.521484 
	C41.914833,441.382324 40.028221,435.185272 37.733295,429.143280 
	C37.133263,427.563599 35.450104,425.624725 33.945290,425.291382 
	C27.082949,423.771271 25.204203,425.055634 23.011959,431.672241 
	C22.125389,434.347992 21.188335,437.007050 20.303133,439.683228 
	C16.098177,452.395966 11.903189,465.111938 7.625203,478.067383 
	C12.432356,479.216003 15.601276,478.941254 16.540890,473.387207 
	C17.192778,469.533905 18.891857,465.857788 20.105436,462.156250 
	C27.420174,462.156250 34.397583,462.156250 41.633381,462.156250 
	C42.834427,465.963165 44.401001,469.655426 45.118095,473.505859 
	C46.143574,479.012238 49.449341,479.181671 54.119965,478.099884 
	C50.739807,468.032471 47.427090,458.165924 43.953888,447.521484 
M820.998718,474.197876 
	C822.512268,476.647003 823.159180,480.663025 827.666809,477.793518 
	C827.666809,460.238556 827.666809,442.635834 827.666809,424.740265 
	C825.409302,424.740265 823.350647,424.740265 820.954346,424.740265 
	C820.954346,430.725342 820.954346,436.462524 820.954346,442.458466 
	C818.207275,441.671204 815.865540,440.841003 813.455627,440.336792 
	C802.446533,438.033447 795.787415,441.910553 794.352722,452.039551 
	C793.660400,456.926849 793.632935,462.082214 794.354065,466.960999 
	C796.054871,478.467163 804.651855,482.735748 815.276855,477.761658 
	C817.071899,476.921356 818.687500,475.697815 820.998718,474.197876 
M218.544785,454.124176 
	C218.598373,458.096222 218.254135,462.122467 218.786636,466.029236 
	C219.971756,474.724182 223.681000,478.556305 232.359726,479.086426 
	C238.077271,479.435638 243.882507,478.381653 249.644363,477.906433 
	C250.067261,477.871582 250.456924,477.434143 251.138351,477.013153 
	C250.772751,475.660919 250.407455,474.309875 250.034119,472.929047 
	C245.321869,473.237762 241.008148,473.828064 236.709198,473.735199 
	C228.800323,473.564362 226.115433,470.743927 226.035416,463.020569 
	C231.187485,463.020569 236.340683,462.935486 241.489624,463.046844 
	C245.795349,463.139954 249.417999,462.313110 251.092667,457.705231 
	C252.918091,452.682526 251.987030,445.999359 248.399887,443.346558 
	C241.930664,438.562469 234.540451,439.174805 227.362045,441.099274 
	C221.450470,442.684082 219.348419,447.615723 218.544785,454.124176 
M745.958130,450.668732 
	C745.998230,456.766602 745.532043,462.923767 746.237061,468.943787 
	C746.812195,473.855713 750.238770,477.337555 755.238770,478.549377 
	C762.820740,480.387024 770.292603,479.590454 778.027100,477.423859 
	C777.525757,475.614655 777.153931,474.272705 776.979065,473.641479 
	C771.619690,473.641479 766.787781,473.980927 762.022827,473.561096 
	C755.059021,472.947510 752.935669,470.278046 752.884155,463.018280 
	C757.868835,463.018280 762.856873,462.906830 767.837402,463.051453 
	C772.295654,463.180908 776.189270,462.537079 777.933533,457.690918 
	C779.810303,452.476837 778.664124,446.458282 775.198914,443.325348 
	C767.102844,436.005585 750.128601,439.824493 745.958130,450.668732 
M623.888733,456.300873 
	C624.727173,446.139191 622.168152,441.550964 613.946472,440.429230 
	C609.440125,439.814392 604.666382,440.325989 600.125488,441.041809 
	C595.872681,441.712280 592.615662,444.737335 592.109802,448.968292 
	C591.351013,455.315369 590.780518,461.877075 591.571960,468.166046 
	C592.453796,475.173187 596.641724,478.626404 603.873169,479.014618 
	C609.774963,479.331390 615.746338,478.381927 621.683533,477.948761 
	C622.112061,477.917480 622.513733,477.517426 623.187317,477.145569 
	C622.975952,475.774689 622.764832,474.405365 622.596130,473.311432 
	C616.684875,473.311432 611.186462,473.737061 605.785339,473.194092 
	C600.159546,472.628540 598.019531,469.160461 598.572449,463.019257 
	C603.882202,463.019257 609.211304,462.908386 614.533447,463.053101 
	C619.078308,463.176666 622.289673,461.572540 623.888733,456.300873 
M367.938507,475.584991 
	C376.784210,482.003357 386.174896,479.247284 396.013489,477.717316 
	C395.614410,475.765991 395.331970,474.384918 395.170044,473.593170 
	C389.722504,473.593170 384.723236,473.959015 379.799805,473.504120 
	C373.069672,472.882324 371.207031,470.460938 370.866974,463.020050 
	C376.498779,463.020050 382.174408,463.473450 387.721680,462.826569 
	C390.448944,462.508514 394.022980,460.800720 395.338013,458.600616 
	C399.773895,451.179291 395.573364,441.970825 387.111481,440.631622 
	C382.321136,439.873474 377.164429,440.303955 372.363861,441.210938 
	C369.958191,441.665436 367.378418,443.981537 365.920746,446.155396 
	C361.316528,453.021484 362.342651,468.251862 367.938507,475.584991 
M504.319824,479.193359 
	C510.516724,478.954254 516.713623,478.715149 523.347534,478.459198 
	C522.976929,475.821625 522.784241,474.450653 522.640198,473.425720 
	C516.906616,473.425720 511.579865,473.839386 506.346405,473.317566 
	C500.261169,472.710754 498.116760,469.512512 498.515930,463.018097 
	C504.156982,463.018097 509.811676,462.977783 515.465515,463.030945 
	C519.950073,463.073120 522.707886,460.827332 523.827942,456.650696 
	C526.227173,447.703979 521.575439,440.975372 512.362610,440.105591 
	C509.557251,439.840729 506.688049,439.841248 503.880646,440.089050 
	C496.706421,440.722290 492.310822,444.826538 491.140320,451.932007 
	C490.709381,454.548004 490.478394,457.231842 490.501404,459.881744 
	C490.608521,472.218079 493.887817,477.003632 504.319824,479.193359 
M302.021454,471.381409 
	C302.019989,464.217010 302.161804,457.048920 301.975861,449.889313 
	C301.797577,443.026428 298.383881,439.363312 291.620117,440.174713 
	C285.960785,440.853546 280.507080,443.247040 275.016541,444.865295 
	C274.876434,444.059845 274.625793,442.618958 274.364471,441.116486 
	C272.429810,441.116486 270.713593,441.116486 269.047913,441.116486 
	C269.047913,453.703857 269.047913,465.975189 269.047913,478.270874 
	C271.227478,478.270874 273.158295,478.270874 274.981812,478.270874 
	C275.228455,477.705658 275.466248,477.412933 275.468445,477.118408 
	C275.511810,471.288330 275.539948,465.458099 275.546844,459.627869 
	C275.557434,450.682953 276.332001,449.602997 284.960266,446.637970 
	C292.232544,444.138916 294.967133,446.026947 294.976288,453.576447 
	C294.986298,461.826904 294.978546,470.077393 294.978546,478.187500 
	C297.743408,478.187500 299.805237,478.187500 302.021454,478.187500 
	C302.021454,475.998260 302.021454,474.170258 302.021454,471.381409 
M721.137939,471.359894 
	C721.137939,473.624146 721.137939,475.888397 721.137939,478.224426 
	C723.809692,478.224426 725.867065,478.224426 728.076355,478.224426 
	C728.076355,468.266602 728.259216,458.623688 728.010132,448.991974 
	C727.851807,442.867889 724.508423,439.422333 718.456909,440.173523 
	C712.566772,440.904694 706.873169,443.218781 701.145813,444.823120 
	C701.056885,444.198456 700.832825,442.624756 700.616150,441.102661 
	C698.598572,441.102661 696.846497,441.102661 695.248169,441.102661 
	C695.248169,453.664917 695.248169,465.928223 695.248169,478.156616 
	C697.590149,478.156616 699.639465,478.156616 701.862122,478.156616 
	C701.862122,470.111877 701.804504,462.454254 701.916809,454.799133 
	C701.936340,453.469543 702.280884,451.420380 703.148010,450.953888 
	C706.757324,449.011963 710.488159,446.929413 714.430176,446.064819 
	C719.425659,444.969116 721.062927,446.761993 721.121216,451.909698 
	C721.190979,458.071533 721.137878,464.234741 721.137939,471.359894 
M349.583710,477.065857 
	C349.316833,475.704346 349.049957,474.342834 348.798950,473.062347 
	C329.588165,474.140808 326.573853,472.601013 326.037079,461.720123 
	C325.364807,448.094055 327.349884,446.648254 348.161926,445.848206 
	C348.430908,444.549103 348.716797,443.168579 349.229706,440.691528 
	C342.898926,440.505768 337.124023,439.817566 331.450867,440.307739 
	C325.079102,440.858276 320.447144,444.533264 319.752045,451.262970 
	C319.194550,456.660492 318.864105,462.248779 319.639465,467.576843 
	C320.762512,475.294189 324.535492,478.651489 332.395416,479.041748 
	C337.937531,479.316925 343.548218,478.210663 349.583710,477.065857 
M200.865433,468.450195 
	C200.865433,459.358185 200.865433,450.266174 200.865433,441.066406 
	C198.307526,441.066406 196.222092,441.066406 193.986099,441.066406 
	C193.986099,453.140533 193.982285,464.949982 194.000427,476.759399 
	C194.001144,477.219574 194.212372,477.679443 194.341110,478.202881 
	C196.488342,478.202881 198.544083,478.202881 200.865417,478.202881 
	C200.865417,475.049561 200.865417,472.238403 200.865433,468.450195 
M675.901428,429.362183 
	C676.583496,423.121826 672.310669,425.174286 667.571045,425.132843 
	C668.671387,428.318726 668.908997,431.626648 670.685852,433.260468 
	C671.939209,434.412842 676.164490,434.756714 675.901428,429.362183 
M194.489700,433.068329 
	C196.533951,433.214539 198.578201,433.360779 200.649719,433.508911 
	C200.649719,430.302094 200.649719,427.777802 200.649719,425.297974 
	C198.228897,425.297974 196.188278,425.297974 193.998856,425.297974 
	C193.998856,427.897217 193.998856,430.175079 194.489700,433.068329 
z"/>
<path fill="#215FCB" opacity="1.000000" stroke="none" 
	d="
M569.353088,323.325562 
	C570.922668,321.617157 572.255005,320.172882 573.864929,318.427643 
	C581.770569,325.717377 589.432007,332.781891 597.085632,339.839264 
	C550.053528,393.739532 480.866547,407.243164 422.923492,378.386353 
	C360.850800,347.472931 330.275360,276.007965 349.656403,209.545273 
	C364.376495,159.066162 417.642487,101.337799 500.904053,109.209389 
	C563.007263,115.080650 621.465210,166.436295 627.889832,238.656647 
	C557.343567,238.656647 486.827454,238.656647 416.073944,238.656647 
	C416.073944,228.270737 416.073944,218.019852 416.073944,207.299561 
	C473.160248,207.299561 530.072632,207.299561 587.048828,207.299561 
	C582.949036,178.004135 533.880920,143.259842 492.931000,140.180954 
	C436.503540,135.938400 391.096924,182.234634 379.773315,220.917953 
	C365.492523,269.703491 387.633209,322.818390 432.942657,348.058289 
	C472.422363,370.050781 532.456848,367.321014 569.353088,323.325562 
z"/>
<path fill="#215FCB" opacity="1.000000" stroke="none" 
	d="
M101.145622,350.945862 
	C134.505768,366.557770 167.508575,365.004486 200.670624,350.385712 
	C204.930634,360.091156 208.984634,369.327240 213.646454,379.948120 
	C201.760620,383.613861 190.467697,388.154633 178.736526,390.541901 
	C106.364342,405.269562 42.748512,363.272003 20.032249,308.511536 
	C-3.111902,252.719650 5.133765,200.291199 45.654472,154.711060 
	C114.603302,77.153252 243.662964,98.586121 284.339661,199.358322 
	C290.309784,214.148682 293.109924,229.711044 293.135956,245.674026 
	C293.210663,291.494720 293.162292,337.315613 293.162292,383.136444 
	C293.162292,384.603760 293.162292,386.071106 293.162292,387.848816 
	C282.717468,387.848816 272.650909,387.848816 261.997467,387.848816 
	C261.997467,386.038300 261.997437,384.262146 261.997437,382.485992 
	C261.997406,339.164490 261.606750,295.838379 262.116943,252.522903 
	C262.551392,215.636703 247.958618,186.407547 219.363312,163.839798 
	C150.962769,109.857346 59.863102,152.355591 42.572662,223.526611 
	C30.314972,273.981812 53.583710,325.006653 101.145622,350.945862 
z"/>
<path fill="#2160CC" opacity="1.000000" stroke="none" 
	d="
M755.219116,160.174072 
	C731.472229,178.653793 715.453247,201.606354 710.618835,231.439636 
	C709.638123,237.491714 709.090637,243.682327 709.072815,249.811188 
	C708.944458,293.963531 709.004028,338.116425 709.004028,382.269135 
	C709.004028,384.067688 709.004089,385.866211 709.004089,387.903534 
	C698.472961,387.903534 688.379700,387.903534 677.680420,387.903534 
	C677.596252,386.176941 677.437805,384.436707 677.437317,382.696411 
	C677.423645,338.710297 677.755493,294.721222 677.356140,250.738739 
	C676.593811,166.779739 743.266418,117.032471 797.482300,109.390022 
	C806.997437,108.048729 816.688965,107.958847 826.756592,107.259232 
	C826.756592,118.217789 826.756592,128.413101 826.756592,139.186462 
	C800.701721,138.156113 776.636536,144.162018 755.219116,160.174072 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M474.545471,440.784363 
	C474.104370,446.968628 472.793610,452.154510 465.751556,453.723145 
	C473.382965,460.351837 472.803802,469.891113 476.367126,477.898865 
	C468.935516,479.258636 468.937225,479.258148 467.204041,473.072998 
	C466.175354,469.401886 465.130707,465.735229 464.110626,462.061737 
	C463.091705,458.392456 460.805084,456.461853 456.885315,456.544037 
	C453.408997,456.616974 449.929932,456.559296 446.084473,456.559296 
	C446.084473,463.494995 446.084473,470.591980 446.084473,478.121399 
	C443.552765,478.121399 441.379730,478.121399 438.986328,478.121399 
	C438.986328,460.505127 438.986328,442.937012 438.986328,424.461639 
	C446.098938,424.461639 453.065033,424.141083 459.990540,424.535004 
	C471.032410,425.163116 473.699097,428.288727 474.545471,440.784363 
M458.161652,430.733002 
	C454.095612,430.733002 450.029572,430.733002 446.156769,430.733002 
	C446.156769,437.693451 446.156769,443.784332 446.156769,450.117249 
	C450.417542,450.117249 454.393463,450.218719 458.362274,450.094055 
	C464.677490,449.895630 466.909821,447.699219 467.131134,441.748260 
	C467.436035,433.548157 466.078308,431.709869 458.161652,430.733002 
z"/>
<path fill="#0C0C0C" opacity="1.000000" stroke="none" 
	d="
M99.178146,477.045715 
	C97.231621,477.926697 95.302216,478.392639 92.801727,478.996521 
	C92.801727,470.409363 92.842728,462.617126 92.789597,454.825500 
	C92.741486,447.769958 91.254250,445.755463 86.391205,445.868286 
	C80.298523,446.009644 74.878204,450.519318 74.868851,455.485657 
	C74.854790,462.952393 74.865189,470.419189 74.865189,478.216431 
	C72.508827,478.216431 70.452400,478.216431 68.184677,478.216431 
	C68.184677,465.826965 68.184677,453.566711 68.184677,441.053558 
	C69.765007,441.053558 71.360191,441.053558 73.307564,441.053558 
	C73.610413,442.567780 73.920982,444.120667 74.231873,445.673676 
	C88.032768,437.817383 93.502632,437.794373 98.340485,444.765594 
	C103.717834,443.108398 108.706978,440.784149 113.897972,440.172150 
	C120.247055,439.423645 123.743813,443.273041 123.832153,449.790100 
	C123.958420,459.105316 123.864319,468.423523 123.864319,478.144775 
	C121.669540,478.144775 119.635498,478.144775 117.133041,478.144775 
	C117.133041,470.075500 117.204491,462.132324 117.113411,454.191010 
	C117.017838,445.856720 112.992683,443.550201 105.528732,447.380829 
	C101.323395,449.539093 98.384346,451.984741 99.035263,457.673859 
	C99.749596,463.917175 99.196243,470.305573 99.178146,477.045715 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M539.442627,463.586792 
	C539.655823,458.840851 539.419678,454.459015 540.202209,450.267365 
	C541.805298,441.679565 548.905945,438.131744 559.219116,440.367737 
	C561.598938,440.883728 563.915100,441.693573 566.438293,442.419312 
	C566.438293,439.354950 566.438293,436.562469 566.438293,433.769958 
	C566.438293,430.953094 566.438293,428.136230 566.438293,424.879669 
	C568.663574,424.879669 570.701355,424.879669 572.976929,424.879669 
	C572.976929,442.514191 572.976929,460.248932 572.976929,477.948212 
	C569.051697,479.993805 567.714966,477.642090 567.115234,474.329346 
	C564.198853,475.828674 561.680969,477.493195 558.920471,478.476257 
	C548.734314,482.103760 541.482910,477.647095 539.889099,466.997314 
	C539.741516,466.010925 539.599121,465.023773 539.442627,463.586792 
M547.031921,451.982025 
	C547.035583,457.083008 546.683105,462.217102 547.153320,467.274689 
	C547.542969,471.466553 550.514893,473.477081 554.804565,473.463837 
	C559.341980,473.449829 566.141235,470.117432 566.302185,467.229645 
	C566.625000,461.439117 566.444397,455.619415 566.414368,449.812073 
	C566.411255,449.209991 566.184631,448.459076 565.787537,448.032288 
	C561.921753,443.877380 549.954651,445.916016 547.031921,451.982025 
z"/>
<path fill="#111111" opacity="1.000000" stroke="none" 
	d="
M150.506012,444.648193 
	C153.117340,443.250763 155.588745,441.405426 158.365631,440.537933 
	C167.588425,437.656799 174.841812,442.199829 175.956894,451.777954 
	C176.546158,456.839417 176.491272,462.072449 175.952240,467.145660 
	C175.176880,474.443115 170.984131,478.759369 163.617569,479.077606 
	C156.639633,479.378998 149.605194,478.373047 142.565613,477.940216 
	C142.565613,459.452759 142.565613,442.363983 142.565613,424.852386 
	C144.689804,424.852386 146.725708,424.852386 149.203781,424.852386 
	C149.203781,430.750641 149.203781,436.701080 149.142456,443.373810 
	C149.556091,444.280090 150.031052,444.464142 150.506012,444.648193 
M160.129562,473.789215 
	C167.124695,472.168030 168.707565,470.524902 169.262421,463.472321 
	C169.508682,460.342072 169.431610,457.143372 169.096481,454.019165 
	C168.335648,446.926392 163.366287,444.083221 156.687698,446.710480 
	C150.407089,449.181183 149.197998,450.956360 149.197815,457.707336 
	C149.197754,459.539642 149.197800,461.371918 149.197800,463.204224 
	C149.197815,473.081055 149.197815,473.081055 160.129562,473.789215 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M668.974915,450.994141 
	C668.974915,449.170685 668.974915,447.852814 668.974915,446.368774 
	C662.875732,446.368774 657.093079,446.368774 650.966064,446.368774 
	C650.966064,456.932159 650.966064,467.388336 650.966064,478.197998 
	C648.569885,478.197998 646.512573,478.197998 644.119263,478.197998 
	C644.119263,467.708282 644.119263,457.269562 644.119263,446.506042 
	C641.733032,446.322327 639.643982,446.161499 637.346680,445.984650 
	C637.346680,444.489868 637.346680,443.059540 637.346680,441.341431 
	C639.443481,441.139740 641.384521,440.953033 643.634277,440.736633 
	C644.791443,435.402008 641.820129,428.064545 648.167908,425.051300 
	C652.644653,422.926239 658.299194,423.282562 663.769104,422.481018 
	C663.903381,425.285461 663.972961,426.737946 664.080505,428.984711 
	C655.536255,427.961151 648.171631,428.704712 651.426270,440.715271 
	C659.143311,440.715271 667.051453,440.715271 675.253845,440.715271 
	C675.253845,453.342926 675.253845,465.570404 675.253845,478.178101 
	C673.375549,478.178101 671.485107,478.178101 668.974915,478.178101 
	C668.974915,469.354706 668.974915,460.427185 668.974915,450.994141 
z"/>
<path fill="#0C0C0C" opacity="1.000000" stroke="none" 
	d="
M44.034130,447.910431 
	C47.427090,458.165924 50.739807,468.032471 54.119965,478.099884 
	C49.449341,479.181671 46.143574,479.012238 45.118095,473.505859 
	C44.401001,469.655426 42.834427,465.963165 41.633381,462.156250 
	C34.397583,462.156250 27.420174,462.156250 20.105436,462.156250 
	C18.891857,465.857788 17.192778,469.533905 16.540890,473.387207 
	C15.601276,478.941254 12.432356,479.216003 7.625203,478.067383 
	C11.903189,465.111938 16.098177,452.395966 20.303133,439.683228 
	C21.188335,437.007050 22.125389,434.347992 23.011959,431.672241 
	C25.204203,425.055634 27.082949,423.771271 33.945290,425.291382 
	C35.450104,425.624725 37.133263,427.563599 37.733295,429.143280 
	C40.028221,435.185272 41.914833,441.382324 44.034130,447.910431 
M35.061081,441.397888 
	C33.919701,437.778503 32.778320,434.159149 31.636942,430.539795 
	C31.086382,430.596344 30.535822,430.652893 29.985262,430.709442 
	C27.359539,438.978455 24.733814,447.247498 22.063282,455.657623 
	C28.006969,455.657623 33.465847,455.657623 39.061455,455.657623 
	C39.061455,455.001221 39.180130,454.480499 39.042923,454.039337 
	C37.810188,450.075806 36.532467,446.126282 35.061081,441.397888 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M820.692383,474.424622 
	C818.687500,475.697815 817.071899,476.921356 815.276855,477.761658 
	C804.651855,482.735748 796.054871,478.467163 794.354065,466.960999 
	C793.632935,462.082214 793.660400,456.926849 794.352722,452.039551 
	C795.787415,441.910553 802.446533,438.033447 813.455627,440.336792 
	C815.865540,440.841003 818.207275,441.671204 820.954346,442.458466 
	C820.954346,436.462524 820.954346,430.725342 820.954346,424.740265 
	C823.350647,424.740265 825.409302,424.740265 827.666809,424.740265 
	C827.666809,442.635834 827.666809,460.238556 827.666809,477.793518 
	C823.159180,480.663025 822.512268,476.647003 820.692383,474.424622 
M801.020996,455.010834 
	C801.303284,459.459106 801.117615,464.001831 801.991150,468.330780 
	C802.992798,473.294403 807.137146,473.848450 811.426270,473.252838 
	C816.914978,472.490723 820.941406,469.060028 820.968628,465.156219 
	C821.007874,459.512482 820.978638,453.868286 820.978638,448.323547 
	C809.017395,443.418823 803.118347,445.191376 801.020996,455.010834 
z"/>
<path fill="#02388C" opacity="1.000000" stroke="none" 
	d="
M504.945923,91.233994 
	C492.413605,91.233994 479.881256,91.233994 466.678558,91.233994 
	C475.096313,77.454071 483.189789,64.205009 491.535767,50.921127 
	C491.788239,50.886303 491.917419,51.213364 491.958862,51.576019 
	C494.243530,58.473503 496.550262,64.987419 498.712097,71.549065 
	C500.865692,78.085777 502.873932,84.670387 504.945923,91.233994 
z"/>
<path fill="#3577A4" opacity="1.000000" stroke="none" 
	d="
M491.825104,50.714386 
	C499.705872,37.456707 507.549774,24.370941 515.952271,11.303396 
	C521.076660,24.582529 525.642517,37.843441 530.208313,51.104351 
	C530.208313,51.104351 530.091187,51.131001 529.594910,51.067421 
	C516.704895,51.073681 504.311157,51.143520 491.917419,51.213364 
	C491.917419,51.213364 491.788239,50.886303 491.825104,50.714386 
z"/>
<path fill="#57B8B5" opacity="1.000000" stroke="none" 
	d="
M530.471924,50.898018 
	C525.642517,37.843441 521.076660,24.582529 516.223877,11.094099 
	C516.651001,10.579448 517.364197,10.045058 518.079590,10.042015 
	C530.172546,9.990561 542.265808,10.005188 555.265015,10.005188 
	C546.867371,23.934101 538.801453,37.312893 530.471924,50.898018 
z"/>
<path fill="#085DB2" opacity="1.000000" stroke="none" 
	d="
M491.958862,51.576019 
	C504.311157,51.143520 516.704895,51.073681 529.570190,51.012695 
	C521.877014,64.484154 513.712219,77.946754 505.246704,91.321671 
	C502.873932,84.670387 500.865692,78.085777 498.712097,71.549065 
	C496.550262,64.987419 494.243530,58.473503 491.958862,51.576019 
z"/>
<path fill="#0B0B0B" opacity="1.000000" stroke="none" 
	d="
M218.620209,453.699219 
	C219.348419,447.615723 221.450470,442.684082 227.362045,441.099274 
	C234.540451,439.174805 241.930664,438.562469 248.399887,443.346558 
	C251.987030,445.999359 252.918091,452.682526 251.092667,457.705231 
	C249.417999,462.313110 245.795349,463.139954 241.489624,463.046844 
	C236.340683,462.935486 231.187485,463.020569 226.035416,463.020569 
	C226.115433,470.743927 228.800323,473.564362 236.709198,473.735199 
	C241.008148,473.828064 245.321869,473.237762 250.034119,472.929047 
	C250.407455,474.309875 250.772751,475.660919 251.138351,477.013153 
	C250.456924,477.434143 250.067261,477.871582 249.644363,477.906433 
	C243.882507,478.381653 238.077271,479.435638 232.359726,479.086426 
	C223.681000,478.556305 219.971756,474.724182 218.786636,466.029236 
	C218.254135,462.122467 218.598373,458.096222 218.620209,453.699219 
M236.300507,457.829407 
	C237.457977,457.828644 238.813141,458.214722 239.731262,457.745575 
	C241.571548,456.805176 244.531937,455.448761 244.580368,454.171265 
	C244.671524,451.766602 243.389145,447.463379 242.034836,447.195068 
	C237.898026,446.375549 232.935669,445.960938 229.313675,447.617279 
	C226.866043,448.736633 226.138962,453.618286 224.168686,457.829407 
	C229.126511,457.829407 232.243042,457.829407 236.300507,457.829407 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M746.033813,450.269318 
	C750.128601,439.824493 767.102844,436.005585 775.198914,443.325348 
	C778.664124,446.458282 779.810303,452.476837 777.933533,457.690918 
	C776.189270,462.537079 772.295654,463.180908 767.837402,463.051453 
	C762.856873,462.906830 757.868835,463.018280 752.884155,463.018280 
	C752.935669,470.278046 755.059021,472.947510 762.022827,473.561096 
	C766.787781,473.980927 771.619690,473.641479 776.979065,473.641479 
	C777.153931,474.272705 777.525757,475.614655 778.027100,477.423859 
	C770.292603,479.590454 762.820740,480.387024 755.238770,478.549377 
	C750.238770,477.337555 746.812195,473.855713 746.237061,468.943787 
	C745.532043,462.923767 745.998230,456.766602 746.033813,450.269318 
M755.912476,457.837433 
	C759.384399,457.837128 762.913879,458.210907 766.305664,457.689331 
	C768.194153,457.398926 771.212830,455.739044 771.325195,454.482117 
	C771.541321,452.064270 770.836548,448.454468 769.133362,447.171265 
	C762.567383,442.224548 752.749512,447.053955 752.422485,455.123840 
	C752.387329,455.991669 754.111389,456.930847 755.912476,457.837433 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M623.776306,456.666412 
	C622.289673,461.572540 619.078308,463.176666 614.533447,463.053101 
	C609.211304,462.908386 603.882202,463.019257 598.572449,463.019257 
	C598.019531,469.160461 600.159546,472.628540 605.785339,473.194092 
	C611.186462,473.737061 616.684875,473.311432 622.596130,473.311432 
	C622.764832,474.405365 622.975952,475.774689 623.187317,477.145569 
	C622.513733,477.517426 622.112061,477.917480 621.683533,477.948761 
	C615.746338,478.381927 609.774963,479.331390 603.873169,479.014618 
	C596.641724,478.626404 592.453796,475.173187 591.571960,468.166046 
	C590.780518,461.877075 591.351013,455.315369 592.109802,448.968292 
	C592.615662,444.737335 595.872681,441.712280 600.125488,441.041809 
	C604.666382,440.325989 609.440125,439.814392 613.946472,440.429230 
	C622.168152,441.550964 624.727173,446.139191 623.776306,456.666412 
M601.859070,446.999786 
	C597.905823,449.541962 597.801331,453.396088 598.088989,457.829224 
	C602.581360,457.829224 606.874878,457.770935 611.166077,457.847870 
	C614.533081,457.908173 617.066101,457.251160 617.194031,453.095245 
	C617.313110,449.232269 616.074890,446.381134 611.945435,446.102295 
	C608.862671,445.894135 605.728821,446.442841 601.859070,446.999786 
z"/>
<path fill="#0B0B0B" opacity="1.000000" stroke="none" 
	d="
M367.675446,475.322357 
	C362.342651,468.251862 361.316528,453.021484 365.920746,446.155396 
	C367.378418,443.981537 369.958191,441.665436 372.363861,441.210938 
	C377.164429,440.303955 382.321136,439.873474 387.111481,440.631622 
	C395.573364,441.970825 399.773895,451.179291 395.338013,458.600616 
	C394.022980,460.800720 390.448944,462.508514 387.721680,462.826569 
	C382.174408,463.473450 376.498779,463.020050 370.866974,463.020050 
	C371.207031,470.460938 373.069672,472.882324 379.799805,473.504120 
	C384.723236,473.959015 389.722504,473.593170 395.170044,473.593170 
	C395.331970,474.384918 395.614410,475.765991 396.013489,477.717316 
	C386.174896,479.247284 376.784210,482.003357 367.675446,475.322357 
M386.667297,457.692017 
	C387.743317,455.542664 389.810486,453.291962 389.604034,451.273834 
	C389.414703,449.423279 386.964386,446.603516 385.177887,446.322235 
	C376.937103,445.024719 370.139557,445.174194 370.490051,457.407928 
	C370.796326,457.526520 371.101868,457.746277 371.409027,457.748535 
	C376.205383,457.783600 381.002014,457.790588 386.667297,457.692017 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M503.901459,479.162689 
	C493.887817,477.003632 490.608521,472.218079 490.501404,459.881744 
	C490.478394,457.231842 490.709381,454.548004 491.140320,451.932007 
	C492.310822,444.826538 496.706421,440.722290 503.880646,440.089050 
	C506.688049,439.841248 509.557251,439.840729 512.362610,440.105591 
	C521.575439,440.975372 526.227173,447.703979 523.827942,456.650696 
	C522.707886,460.827332 519.950073,463.073120 515.465515,463.030945 
	C509.811676,462.977783 504.156982,463.018097 498.515930,463.018097 
	C498.116760,469.512512 500.261169,472.710754 506.346405,473.317566 
	C511.579865,473.839386 516.906616,473.425720 522.640198,473.425720 
	C522.784241,474.450653 522.976929,475.821625 523.347534,478.459198 
	C516.713623,478.715149 510.516724,478.954254 503.901459,479.162689 
M517.141541,450.836548 
	C515.218079,446.156586 510.868073,444.547546 504.239868,446.064331 
	C499.572449,447.132446 497.674042,450.749725 498.302643,457.829468 
	C502.393768,457.829468 506.536102,457.645966 510.654938,457.878540 
	C515.212891,458.135986 517.692749,456.562805 517.141541,450.836548 
z"/>
<path fill="#0B0B0B" opacity="1.000000" stroke="none" 
	d="
M302.021454,471.861847 
	C302.021454,474.170258 302.021454,475.998260 302.021454,478.187500 
	C299.805237,478.187500 297.743408,478.187500 294.978546,478.187500 
	C294.978546,470.077393 294.986298,461.826904 294.976288,453.576447 
	C294.967133,446.026947 292.232544,444.138916 284.960266,446.637970 
	C276.332001,449.602997 275.557434,450.682953 275.546844,459.627869 
	C275.539948,465.458099 275.511810,471.288330 275.468445,477.118408 
	C275.466248,477.412933 275.228455,477.705658 274.981812,478.270874 
	C273.158295,478.270874 271.227478,478.270874 269.047913,478.270874 
	C269.047913,465.975189 269.047913,453.703857 269.047913,441.116486 
	C270.713593,441.116486 272.429810,441.116486 274.364471,441.116486 
	C274.625793,442.618958 274.876434,444.059845 275.016541,444.865295 
	C280.507080,443.247040 285.960785,440.853546 291.620117,440.174713 
	C298.383881,439.363312 301.797577,443.026428 301.975861,449.889313 
	C302.161804,457.048920 302.019989,464.217010 302.021454,471.861847 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M721.137939,470.878632 
	C721.137878,464.234741 721.190979,458.071533 721.121216,451.909698 
	C721.062927,446.761993 719.425659,444.969116 714.430176,446.064819 
	C710.488159,446.929413 706.757324,449.011963 703.148010,450.953888 
	C702.280884,451.420380 701.936340,453.469543 701.916809,454.799133 
	C701.804504,462.454254 701.862122,470.111877 701.862122,478.156616 
	C699.639465,478.156616 697.590149,478.156616 695.248169,478.156616 
	C695.248169,465.928223 695.248169,453.664917 695.248169,441.102661 
	C696.846497,441.102661 698.598572,441.102661 700.616150,441.102661 
	C700.832825,442.624756 701.056885,444.198456 701.145813,444.823120 
	C706.873169,443.218781 712.566772,440.904694 718.456909,440.173523 
	C724.508423,439.422333 727.851807,442.867889 728.010132,448.991974 
	C728.259216,458.623688 728.076355,468.266602 728.076355,478.224426 
	C725.867065,478.224426 723.809692,478.224426 721.137939,478.224426 
	C721.137939,475.888397 721.137939,473.624146 721.137939,470.878632 
z"/>
<path fill="#0C0C0C" opacity="1.000000" stroke="none" 
	d="
M349.356018,477.393494 
	C343.548218,478.210663 337.937531,479.316925 332.395416,479.041748 
	C324.535492,478.651489 320.762512,475.294189 319.639465,467.576843 
	C318.864105,462.248779 319.194550,456.660492 319.752045,451.262970 
	C320.447144,444.533264 325.079102,440.858276 331.450867,440.307739 
	C337.124023,439.817566 342.898926,440.505768 349.229706,440.691528 
	C348.716797,443.168579 348.430908,444.549103 348.161926,445.848206 
	C327.349884,446.648254 325.364807,448.094055 326.037079,461.720123 
	C326.573853,472.601013 329.588165,474.140808 348.798950,473.062347 
	C349.049957,474.342834 349.316833,475.704346 349.356018,477.393494 
z"/>
<path fill="#0C0C0C" opacity="1.000000" stroke="none" 
	d="
M200.865417,468.938721 
	C200.865417,472.238403 200.865417,475.049561 200.865417,478.202881 
	C198.544083,478.202881 196.488342,478.202881 194.341110,478.202881 
	C194.212372,477.679443 194.001144,477.219574 194.000427,476.759399 
	C193.982285,464.949982 193.986099,453.140533 193.986099,441.066406 
	C196.222092,441.066406 198.307526,441.066406 200.865433,441.066406 
	C200.865433,450.266174 200.865433,459.358185 200.865417,468.938721 
z"/>
<path fill="#191919" opacity="1.000000" stroke="none" 
	d="
M675.901245,429.791962 
	C676.164490,434.756714 671.939209,434.412842 670.685852,433.260468 
	C668.908997,431.626648 668.671387,428.318726 667.571045,425.132843 
	C672.310669,425.174286 676.583496,423.121826 675.901245,429.791962 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M194.244278,432.760651 
	C193.998856,430.175079 193.998856,427.897217 193.998856,425.297974 
	C196.188278,425.297974 198.228897,425.297974 200.649719,425.297974 
	C200.649719,427.777802 200.649719,430.302094 200.649719,433.508911 
	C198.578201,433.360779 196.533951,433.214539 194.244278,432.760651 
z"/>
<path fill="#FDFDFD" opacity="1.000000" stroke="none" 
	d="
M458.616760,430.784729 
	C466.078308,431.709869 467.436035,433.548157 467.131134,441.748260 
	C466.909821,447.699219 464.677490,449.895630 458.362274,450.094055 
	C454.393463,450.218719 450.417542,450.117249 446.156769,450.117249 
	C446.156769,443.784332 446.156769,437.693451 446.156769,430.733002 
	C450.029572,430.733002 454.095612,430.733002 458.616760,430.784729 
z"/>
<path fill="#FBFBFB" opacity="1.000000" stroke="none" 
	d="
M547.103455,451.573120 
	C549.954651,445.916016 561.921753,443.877380 565.787537,448.032288 
	C566.184631,448.459076 566.411255,449.209991 566.414368,449.812073 
	C566.444397,455.619415 566.625000,461.439117 566.302185,467.229645 
	C566.141235,470.117432 559.341980,473.449829 554.804565,473.463837 
	C550.514893,473.477081 547.542969,471.466553 547.153320,467.274689 
	C546.683105,462.217102 547.035583,457.083008 547.103455,451.573120 
z"/>
<path fill="#FAFAFA" opacity="1.000000" stroke="none" 
	d="
M159.718277,473.794983 
	C149.197815,473.081055 149.197815,473.081055 149.197800,463.204224 
	C149.197800,461.371918 149.197754,459.539642 149.197815,457.707336 
	C149.197998,450.956360 150.407089,449.181183 156.687698,446.710480 
	C163.366287,444.083221 168.335648,446.926392 169.096481,454.019165 
	C169.431610,457.143372 169.508682,460.342072 169.262421,463.472321 
	C168.707565,470.524902 167.124695,472.168030 159.718277,473.794983 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M150.213745,444.350769 
	C150.031052,444.464142 149.556091,444.280090 149.183029,443.805237 
	C149.497101,443.694061 149.709290,443.873688 150.213745,444.350769 
z"/>
<path fill="#F4F4F4" opacity="1.000000" stroke="none" 
	d="
M35.164528,441.785278 
	C36.532467,446.126282 37.810188,450.075806 39.042923,454.039337 
	C39.180130,454.480499 39.061455,455.001221 39.061455,455.657623 
	C33.465847,455.657623 28.006969,455.657623 22.063282,455.657623 
	C24.733814,447.247498 27.359539,438.978455 29.985262,430.709442 
	C30.535822,430.652893 31.086382,430.596344 31.636942,430.539795 
	C32.778320,434.159149 33.919701,437.778503 35.164528,441.785278 
z"/>
<path fill="#FDFDFD" opacity="1.000000" stroke="none" 
	d="
M801.043396,454.574341 
	C803.118347,445.191376 809.017395,443.418823 820.978638,448.323547 
	C820.978638,453.868286 821.007874,459.512482 820.968628,465.156219 
	C820.941406,469.060028 816.914978,472.490723 811.426270,473.252838 
	C807.137146,473.848450 802.992798,473.294403 801.991150,468.330780 
	C801.117615,464.001831 801.303284,459.459106 801.043396,454.574341 
z"/>
<path fill="#F5F5F5" opacity="1.000000" stroke="none" 
	d="
M235.830048,457.829407 
	C232.243042,457.829407 229.126511,457.829407 224.168686,457.829407 
	C226.138962,453.618286 226.866043,448.736633 229.313675,447.617279 
	C232.935669,445.960938 237.898026,446.375549 242.034836,447.195068 
	C243.389145,447.463379 244.671524,451.766602 244.580368,454.171265 
	C244.531937,455.448761 241.571548,456.805176 239.731262,457.745575 
	C238.813141,458.214722 237.457977,457.828644 235.830048,457.829407 
z"/>
<path fill="#F3F3F3" opacity="1.000000" stroke="none" 
	d="
M755.465942,457.837189 
	C754.111389,456.930847 752.387329,455.991669 752.422485,455.123840 
	C752.749512,447.053955 762.567383,442.224548 769.133362,447.171265 
	C770.836548,448.454468 771.541321,452.064270 771.325195,454.482117 
	C771.212830,455.739044 768.194153,457.398926 766.305664,457.689331 
	C762.913879,458.210907 759.384399,457.837128 755.465942,457.837189 
z"/>
<path fill="#F6F6F6" opacity="1.000000" stroke="none" 
	d="
M602.238403,446.827148 
	C605.728821,446.442841 608.862671,445.894135 611.945435,446.102295 
	C616.074890,446.381134 617.313110,449.232269 617.194031,453.095245 
	C617.066101,457.251160 614.533081,457.908173 611.166077,457.847870 
	C606.874878,457.770935 602.581360,457.829224 598.088989,457.829224 
	C597.801331,453.396088 597.905823,449.541962 602.238403,446.827148 
z"/>
<path fill="#F4F4F4" opacity="1.000000" stroke="none" 
	d="
M386.232910,457.748047 
	C381.002014,457.790588 376.205383,457.783600 371.409027,457.748535 
	C371.101868,457.746277 370.796326,457.526520 370.490662,457.406372 
	C370.139557,445.174194 376.937103,445.024719 385.177887,446.322235 
	C386.964386,446.603516 389.414703,449.423279 389.604034,451.273834 
	C389.810486,453.291962 387.743317,455.542664 386.232910,457.748047 
z"/>
<path fill="#F6F6F6" opacity="1.000000" stroke="none" 
	d="
M517.202515,451.261169 
	C517.692749,456.562805 515.212891,458.135986 510.654938,457.878540 
	C506.536102,457.645966 502.393768,457.829468 498.302643,457.829468 
	C497.674042,450.749725 499.572449,447.132446 504.239868,446.064331 
	C510.868073,444.547546 515.218079,446.156586 517.202515,451.261169 
z"/>
</svg> */}
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
