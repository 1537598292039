import { Container } from "@mui/material";
import React, { useEffect } from "react";

// hooks
import useSettings from "src/hooks/useSettings";

// routes
import { PATH_DASHBOARD } from "src/routes/paths";

// components
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import Page from "src/components/Page";
import DeviceListTable from "src/components/adminDeviceManagement/DeviceListTable";
import { getAllDevice, getAllUsers } from "src/redux/slices/adminDevices";
import NewDevice from "../../../components/adminDeviceManagement/NewDevice";

const DeviceList = () => {
  const { themeStretch } = useSettings();

  useEffect(() => {
    getAllDevice()
    getAllUsers()
  }, [])

  return (
    <Page title="Device Management: Device list">
      <Container maxWidth={themeStretch ? false : "lg"}>
        <HeaderBreadcrumbs
          heading="Device List"
          links={[
            { name: "Dashboard", href: PATH_DASHBOARD.root },
            {
              name: "Device Management",
              href: PATH_DASHBOARD.adminDeviceManagement.root,
            },
            {
              name: "Device List",
              href: PATH_DASHBOARD.adminDeviceManagement.deviceList,
            },
          ]}
        />
        <NewDevice/>
        <DeviceListTable />
      </Container>
    </Page>
  );
};

export default DeviceList;
