import React from 'react'
// hooks
import useSettings from "src/hooks/useSettings";

import { Container } from "@mui/material";
// routes
import { PATH_DASHBOARD } from "src/routes/paths";
// components
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import Page from "src/components/Page";
import NewActivationKey from 'src/components/adminDeviceManagement/NewActivationKey';
import ActivationKeysTable from 'src/components/adminDeviceManagement/ActivationKeysTable';

function ActivationKeyList() {
  const { themeStretch } = useSettings();

  return (
    <Page title="Device Management: Activation keys">
      <Container maxWidth={themeStretch ? false : "lg"}>
        <HeaderBreadcrumbs
          heading="Activation keys"
          links={[
            { name: "Dashboard", href: PATH_DASHBOARD.root },
            {
              name: "Device Management",
              href: PATH_DASHBOARD.adminDeviceManagement.root,
            },
            {
              name: " Activation keys",
              href: PATH_DASHBOARD.adminDeviceManagement.activationKeys,
            },
          ]}
        />
        <NewActivationKey/>
        <ActivationKeysTable />
      </Container>
    </Page>
  )
}

export default ActivationKeyList