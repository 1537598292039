import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";
import {
  collection,
  getDocs,
  where,
  query,
  doc,
  updateDoc,
  getDoc,
} from "firebase/firestore";

import { DB } from "src/contexts/FirebaseContext";

const initialState = {
  isLoading: false,
  devices: [],
};

const slice = createSlice({
  name: "device",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    getDevicesSuccess(state, action) {
      state.devices = action.payload;
      state.isLoading = false;
    },
  },
});

export default slice.reducer;

export async function getDevicesByUserIdService(id) {
  const devices = [];
  const getDeviceQuery = query(
    collection(DB, "devices"),
    where("userId", "==", id)
  );
  try {
    dispatch(slice.actions.startLoading());
    const querySnapshot = await getDocs(getDeviceQuery);
    querySnapshot.forEach((doc) => {
      devices.push(doc.data());
    });
    dispatch(slice.actions.getDevicesSuccess(devices));
  } catch (error) {
    console.log(error);
  }
}

export async function updateDevice(updatedDevice, userId) {
  const updateDeviceRef = doc(DB, "devices", updatedDevice.serialNo);

  try {
    await updateDoc(updateDeviceRef, {
      name: updatedDevice.name,
    });
    getDevicesByUserIdService(userId);
  } catch (error) {
    console.log(error);
  }
}

export async function removeDevice(deviceSerialNo, userId) {
  const updateDeviceRef = doc(DB, "devices", deviceSerialNo);

  try {
    await updateDoc(updateDeviceRef, {
      name: "",
      runningHrs: 0,
      userId: "",
    });
    await getDevicesByUserIdService(userId);
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
}

export async function checkDeviceExistService(deviceSerialNo) {
  const getDeviceQuery = doc(DB, "devices", deviceSerialNo);

  try {
    const docSnap = await getDoc(getDeviceQuery);

    if (docSnap.exists()) {
      return true;
    }
    return false;
  } catch (error) {
    console.log(error);
  }
}

export async function addDevice(newDevice) {
  const newDeviceRef = doc(DB, "devices", newDevice.serialNo);

  try {
    await updateDoc(newDeviceRef, {
      name: newDevice.name,
      runningHrs: 0,
      userId: newDevice.userId,
    });
    getDevicesByUserIdService(newDevice.userId);
  } catch (error) {
    console.log(error);
    throw new Error(error);
  }
}

export async function isActivationKeyValid(key) {
  const validationQuery = doc(DB, "activationKey", key);
  try {
    const docSnap = await getDoc(validationQuery);
    if (docSnap.exists()) {
      const key = docSnap.data();
      if (key?.email.length !== 0) throw new Error("Invalid activation key");
    } else throw new Error("Invalid activation key");

  } catch (error) {
    throw new Error(error);
  }
}
