import { Box, Button, Container } from "@mui/material";
import React from "react";
import useSettings from "src/hooks/useSettings";
import { PATH_DASHBOARD } from "src/routes/paths";
import { Link as RouterLink } from "react-router-dom";

import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import Page from "src/components/Page";
import Iconify from "src/components/Iconify";
import DeviceCard from "src/components/devices/DeviceCard";
import { useSelector } from "react-redux";

const DeviceDashboard = () => {
  const { themeStretch } = useSettings();
  const { devices } = useSelector((state) => state.device);

  return (
    <Page title="Devices">
      <Container maxWidth={themeStretch ? false : "lg"}>
        <HeaderBreadcrumbs
          heading="Devices"
          links={[
            { name: "Dashboard", href: PATH_DASHBOARD.root },
            { name: "User", href: PATH_DASHBOARD.devices.root },
            { name: "Device dashboard" },
          ]}
          action={
            <Button
              variant="contained"
              component={RouterLink}
              to={PATH_DASHBOARD.devices.new}
              startIcon={<Iconify icon={"eva:plus-fill"} />}
            >
              New Device
            </Button>
          }
        />
        <Box
          sx={{
            display: "grid",
            gap: 3,
            gridTemplateColumns: {
              xs: "repeat(1, 1fr)",
              sm: "repeat(2, 1fr)",
            },
          }}
        >
          {devices.map((device) => (
            <DeviceCard key={device.serialNo} device={device} />
          ))}
        </Box>
      </Container>
    </Page>
  );
};

export default DeviceDashboard;
