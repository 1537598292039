import { async } from "@firebase/util";
import { createSlice } from "@reduxjs/toolkit";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { DB } from "src/contexts/FirebaseContext";
import { dispatch } from "../store";

const initialState = {
  isLoading: false,
  allDevices: [],
  allUsers: [],
  selectedUserOnUsersTable: "",
  allActivationKeys: [],
};

const slice = createSlice({
  name: "adminDevices",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    stopLoading(state) {
      state.isLoading = false;
    },
    getAllDevicesSuccess(state, action) {
      state.allDevices = action.payload;
      state.isLoading = false;
    },

    getAllUsersSuccess(state, action) {
      state.allUsers = action.payload;
      state.isLoading = false;
    },

    selectUserOnUsersTable(state, action) {
      state.selectedUserOnUsersTable = action.payload;
    },

    getAllActivationKeys(state, action){
      state.allActivationKeys = action.payload
      state.isLoading = false
    }
  },
});

export default slice.reducer;

export async function getAllDevice() {
  const allDevice = [];
  const getAllDeviceQuery = query(collection(DB, "devices"));
  try {
    dispatch(slice.actions.startLoading());
    const querySnapshot = await getDocs(getAllDeviceQuery);
    querySnapshot.forEach((doc) => {
      allDevice.push(doc.data());
    });
    dispatch(slice.actions.getAllDevicesSuccess(allDevice));
  } catch (error) {
    console.error(error);
  }
}

export async function getAllUsers() {
  const allUsers = [];
  const getAllUsersQuery = query(
    collection(DB, "users"),
    where("userType", "==", "USER")
  );

  try {
    dispatch(slice.actions.startLoading());
    const querySnapshot = await getDocs(getAllUsersQuery);
    querySnapshot.forEach((doc) => {
      allUsers.push(doc.data());
    });
    dispatch(slice.actions.getAllUsersSuccess(allUsers));
  } catch (error) {
    console.error(error);
  }
}

export async function addNewDevice(serialNo,deviceModel) {
  const newDeviceRef = doc(collection(DB, "devices"), serialNo);
  const newDevice = {
    serialNo: serialNo,
    name: "",
    userId: "",
    runningHrs: 0,
    deviceModel
  };
  try {
    await setDoc(newDeviceRef, newDevice);
    await getAllDevice();
  } catch (error) {}
}

export async function removeDevice(deviceSerialNo) {
  try {
    dispatch(slice.actions.startLoading());
    await deleteDoc(doc(DB, "devices", deviceSerialNo));
    await getAllDevice();
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
}

export async function selectUserOnUserTable(userId) {
  dispatch(slice.actions.selectUserOnUsersTable(userId));
}

export async function addNewActivationKey(key) {
  const newActivationKey = doc(DB, "activationKey", key);
  if (await isExistActivationKey(key)){
    throw new Error("This activation key exists.");
  }
  try {
    await setDoc(newActivationKey, {
      key: key,
      email: "",
    });
    await getActivationKeys()
  } catch (error) {
    throw new Error(error);
  }
}

export async function isExistActivationKey(key) {
  const activationKeyRef = doc(DB, "activationKey", key);
  const docSnap = await getDoc(activationKeyRef);
  if (docSnap.exists()) {
    return true;
  }
  return false;
}

export async function getActivationKeys() {
  const allActivationKeys = [];
  const getallActivationKeysQuery = query(collection(DB, "activationKey"));

  try {
    dispatch(slice.actions.startLoading());
    const querySnapshot = await getDocs(getallActivationKeysQuery);
    querySnapshot.forEach((doc) => {
      allActivationKeys.push(doc.data());
    });
    dispatch(slice.actions.getAllActivationKeys(allActivationKeys));

  } catch (error) {
    console.error(error);
  }
}

export async function removeActivationKey(key) {
  try {
    dispatch(slice.actions.startLoading());
    await deleteDoc(doc(DB, "activationKey", key));
    await getActivationKeys();
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
}