import { Container } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import UserDevicesTable from "src/components/adminDeviceManagement/UserDevicesTable";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import Page from "src/components/Page";
import useSettings from "src/hooks/useSettings";
import { PATH_DASHBOARD } from "src/routes/paths";

const UserDevices = () => {
  let { uid } = useParams();
  uid = "a8hlqu6cOHZUTBqzr5PynkN00HE3";
  const location = useLocation()
  const { themeStretch } = useSettings();
  const selectedUserId = useSelector((state) => state.adminDevices.selectedUserOnUsersTable)
  const allUsers = useSelector((state) => state.adminDevices.allUsers);
  const allDevices = useSelector((state) => state.adminDevices.allDevices);
  const user = allUsers.find((user) => user.uid === selectedUserId);
  const devices = allDevices.filter((device) => device.userId === selectedUserId);

  return (
    <Page title="Device Management: User Devices">
      <Container maxWidth={themeStretch ? false : "lg"}>
        <HeaderBreadcrumbs
          heading={"Users : " + user?.email}
          links={[
            { name: "Dashboard", href: PATH_DASHBOARD.root },
            {
              name: "Users",
              href: PATH_DASHBOARD.adminDeviceManagement.users,
            },
            {
              name: user?.email,
            },
          ]}
        />
        <UserDevicesTable devices={devices} />
      </Container>
    </Page>
  );
};

export default UserDevices;
