import { InputAdornment, Stack, TextField } from '@mui/material';
import React from 'react'
import Iconify from '../Iconify';

const TableToolbar = (props) => {
  const { searchingValue, handleOnSearch, placeholder } = props;
  
  return (
    <Stack
      spacing={2}
      direction={{ xs: "column", sm: "row" }}
      sx={{ py: 2.5, px: 3 }}
    >
      <TextField
        fullWidth
        value={searchingValue}
        onChange={(event) => handleOnSearch(event.target.value)}
        placeholder={placeholder}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify
                icon={"eva:search-fill"}
                sx={{ color: "text.disabled", width: 20, height: 20 }}
              />
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  );
}

export default TableToolbar