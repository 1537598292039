// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// components
// import ThemeSettings from "./components/settings";
import { ChartStyle } from "./components/chart";
import ScrollToTop from "./components/ScrollToTop";
import { ProgressBarStyle } from "./components/ProgressBar";
import NotistackProvider from "./components/NotistackProvider";
import MotionLazyContainer from "./components/animate/MotionLazyContainer";
import { useEffect } from "react";
import useAuth from "./hooks/useAuth";
import { getDevicesByUserIdService } from "./redux/slices/userDevices";
import { USER_TYPE } from "./contants";
import { getAllDevice, getAllUsers } from "./redux/slices/adminDevices";

// ----------------------------------------------------------------------

export default function App() {
  const { user } = useAuth();

  useEffect(() => {
    getDevicesByUserIdService(user.id);

    if(user.userType === USER_TYPE.ADMIN){
      getAllDevice()
      getAllUsers()
    }
  });
  
  return (
    <MotionLazyContainer>
      <ThemeProvider>
        {/* <ThemeSettings> */}
          <NotistackProvider>
            <ProgressBarStyle />
            <ChartStyle />
            <ScrollToTop />
            <Router />
          </NotistackProvider>
        {/* </ThemeSettings> */}
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
