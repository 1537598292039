import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Stack, TextField } from "@mui/material";
import { useFormik, Form, FormikProvider } from "formik";
import { LoadingButton } from "@mui/lab";
import useAuth from "src/hooks/useAuth";
import { useSnackbar } from "notistack";
import {
  addDevice,
  checkDeviceExistService,
  updateDevice,
} from "src/redux/slices/userDevices";
import { useNavigate } from "react-router";
import { PATH_DASHBOARD } from "src/routes/paths";
import AlertDialog from "./AlertDialog";

const DeviceNewForm = (props) => {
  const { isEdit, currentDevice } = props;
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [openAlert, setOpenAlert] = useState(false);

  const NewDeviceSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    serialNo: Yup.string().required("Serial No is required"),
  });

  const initialValues = {
    name: currentDevice?.name || "",
    serialNo: currentDevice?.serialNo || "",
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: NewDeviceSchema,
    onSubmit: async (values, { resetForm }) => {
      // updating
      if (isEdit) {
        try {
          updateDevice(
            { name: values.name, serialNo: values.serialNo },
            user.id
          );
          enqueueSnackbar("Updated device successfully", {
            variant: "success",
          });
        } catch (error) {
          enqueueSnackbar("Failed device updating", { variant: "error" });
        }
      } else {
        const deviceExists = await checkDeviceExistService(values.serialNo);

        // if device does not exist, return
        if (!deviceExists) {
          setOpenAlert(true);
          return;
        }


        const newDevice = {
          serialNo: values.serialNo,
          name: values.name,
          userId: user.id,
          runningHrs: 0,
        };
        try {
          await addDevice(newDevice);
          // resetForm();
          enqueueSnackbar("New device added", { variant: "success" });
          navigate(PATH_DASHBOARD.devices.dashboard);
        } catch (error) {
          enqueueSnackbar(error.message, { variant: "error" });
        }
      }

    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    resetForm,
  } = formik;

  useEffect(() => {
    if (isEdit && currentDevice) {
      resetForm(initialValues);
    }
    if (!isEdit) {
      resetForm(initialValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentDevice]);

  return (
    <>
      <AlertDialog open={openAlert} handleClose={() => setOpenAlert(false)} />
      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Stack
            spacing={3}
            sx={{
              paddingX: 1,
            }}
          >
            <TextField
              fullWidth
              id="deviceName"
              label="Device Name"
              {...getFieldProps("name")}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />

            <TextField
              fullWidth
              id="serialNo"
              label="Serial No"
              {...getFieldProps("serialNo")}
              disabled={isEdit}
              error={Boolean(touched.serialNo && errors.serialNo)}
              helperText={touched.serialNo && errors.serialNo}
            />

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              Add New Device
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>
    </>
  );
};

export default DeviceNewForm;
