import { Container } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import DeviceDetailsCard from "src/components/adminDeviceManagement/DeviceDetailsCard";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import Page from "src/components/Page";
import useSettings from "src/hooks/useSettings";
import { PATH_DASHBOARD } from "src/routes/paths";

const DeviceDetails = () => {
  const { serialNo } = useParams();
  const { themeStretch } = useSettings();
  const allDevices = useSelector((state) => state.adminDevices.allDevices);
  const allUsers = useSelector((state) => state.adminDevices.allUsers);

  const details = allDevices.find(
    (device) => device.serialNo.toLowerCase() === serialNo.toLowerCase()
  );
  const userDetails = allUsers.find((user) => user?.userId?.toLowerCase() === details?.uid)
  console.log(userDetails)
  return (
    <Page title="">
      <Container maxWidth={themeStretch ? false : "lg"}>
        <HeaderBreadcrumbs
          heading={`Serial No : ${serialNo}`}
          links={[
            { name: "Dashboard", href: PATH_DASHBOARD.root },
            {
              name: "Device list",
              href: PATH_DASHBOARD.adminDeviceManagement.root,
            },
            { name: "Serial No:" + serialNo },
          ]}
        />
      </Container>
      <DeviceDetailsCard device={details} userDetails={userDetails} />
    </Page>
  );
};

export default DeviceDetails;
